import * as React from "react";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label, Error, Hint } from "@progress/kendo-react-labels";
import { DatePicker } from "@progress/kendo-react-dateinputs";
const FibaDatePicker = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, required, hintDirection, value, defaultValue, ...others } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";
    const valueF = value ? new Date(value) : null;
    return (
        <FieldWrapper style={wrapperStyle}>
            <Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled}>
                {label}
                {required && <span className="k-required">*</span>}
            </Label>
            <div className={"k-form-field-wrap"}>
                <DatePicker
                    ariaLabelledBy={labelId}
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    valid={valid}
                    id={id}
                    disabled={disabled}
                    value={valueF}
                    required={required}
                    format={'yyyy/MM/dd'}
                    formatPlaceholder={{
                        year: "yyyy",
                        month: "MM",
                        day: "dd",
                    }}
                    defaultValue={defaultValue}
                    {...others}
                />
                {showHint && (
                    <Hint id={hintId} direction={hintDirection}>
                        {hint}
                    </Hint>
                )}
                {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
            </div>
        </FieldWrapper>
    );
};

export default FibaDatePicker;
