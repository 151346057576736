import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { addProduct, getProductDetail, updateProduct, uploadImage } from "../services";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import FibaDropdown from "../../components/input/FibaDropdown";
import FibaInputNumber from "../../components/input/FIbaInputNumber";
import FibaDatePicker from "../../components/input/FibaDatePicker";
import useLoading from "../../hooks/useLoading";
import useFibaMsgBox from "../../hooks/useFibaMsgBox";
import { formValidationFunc } from "../../services/functions";
import FibaInput from "../../components/input/FibaCustomInput";
import { FibaTextArea } from "../../components/input/FibaTextArea";
import FibaRating from "../../components/input/FibaRating";

export default function ProductDetail(props) {
    const { showLoading } = useLoading();
    const { error, success, confirm } = useFibaMsgBox();
    const { t } = useTranslation();
    const [defaultValue, setDefaultVal] = useState({});
    const id = props?.modalState?.data?.id;
    const params = useParams();
    const [show, setShow] = useState(false);
    const [images, setImages] = useState([]);

    const getDetail = async () => {
        if (id) {
            showLoading(true);
            const res = await getProductDetail(id);
            if (res?.status === "error") {
                error(res.message);
                return;
            }
            if (res.images) {
                for (let index = 0; index < res.images.length; index++) {
                    const element = res.images[index];
                    images.push({ data: element.url, isnow: false });
                }
                setImages([...images]);
            }
            setDefaultVal(res);
            showLoading(false);
        }
        setShow(true);
    }

    const onSubmit = async (data) => {
        const isConfirmed = await confirm("Та хадгалахдаа итгэлтэй байна уу?");
        if (isConfirmed) {

            let res = {
                ...data,
                inst_id: params.id,
                images: []
                // usernametype: data.usernametype ? (data.usernametype.value ? data.usernametype.value : data.usernametype) : '',
            };
            for (let index = 0; index < images.length; index++) {
                const element = images[index];
                res.images.push(
                    {
                        url: element.data,
                        ismain: index > 0 ? 0 : 1
                    }
                );
            }
            if (res.images.length === 0) {
                error('Бүтээгдэхүүний зураг сонгоогүй байна.');
                return;
            }
            showLoading(true);
            if (id) {
                res = await updateProduct(res, id);
            } else {
                res = await addProduct(res);
            }
            showLoading(false);
            if (res?.status === "error") {
                error(res.message);
            } else {
                success(t("text.successsave"));
                props.closeModal();
            }
        }

    };

    const validations = {};

    const formValidation = (values) => {
        return formValidationFunc(values, validations, t);
    };

    const removeImage = (index) => {
        images.splice(index, 1);
        setImages([...images]);
    }

    const productImages = images.map((item, index) =>
        <div className="my-2 mx-2" key={index}>
            <div className="fiba-i-error"
                style={{
                    fontSize: '25px',
                    float: 'right',
                    marginTop: '-20px',
                    cursor: 'pointer'
                }}
                onClick={() => { removeImage(index) }}></div>
            <img
                src={item.isnow ?
                    window.URL.createObjectURL(item.data)
                    : (process.env.REACT_APP_SERVER_URL + item.data)}
                alt="Ding logo" height={100}
            />
        </div >
    );

    const onChangePicture = async (e) => {
        const file = e.syntheticEvent?.target?.files[0];
        if (file) {
            const formData = new FormData();
            showLoading(true);
            formData.append('image', file, file?.name);
            const resp = await uploadImage(formData);
            if (resp?.status === "error") {
                error(resp.message);
            } else {
                setImages([...images, ...[{ data: resp.data?.image, isnow: false }]]);
            }
            showLoading(false);
        }
    };


    useEffect(() => {
        getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {show && (
                <Dialog title={id ? t("text.update") : t("text.add")} onClose={props.closeModal} width={'80vw'} height={'90%'}>
                    <Form
                        onSubmit={onSubmit}
                        initialValues={defaultValue}
                        validator={formValidation}
                        ignoreModified={id ? true : false}
                        render={(formRenderProps) => (
                            <FormElement>
                                <DialogActionsBar layout={"end"}>
                                    <button type="button" className="k-button" onClick={formRenderProps.onFormReset}>
                                        {t("text.cancel")}
                                    </button>
                                    <button type="submit" className="k-button k-primary" disabled={!formRenderProps.allowSubmit}>
                                        {t("text.save")}
                                    </button>
                                </DialogActionsBar>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div
                                                className="text-center border"
                                                onClick={() => { document.getElementById('getFile').click() }}
                                                style={{ cursor: 'pointer', paddingTop: '20px', width: '150px' }}
                                            >
                                                <i style={{ fontSize: '45px' }} className="k-icon k-i-inbox"></i>
                                                <div>
                                                    Бүтээгдэхүүний зураг нэмэх
                                                </div>
                                                <Field
                                                    type={"file"}
                                                    id="getFile"
                                                    component={FibaInput}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => onChangePicture(e, 'cover_url')}
                                                />
                                            </div>
                                            {productImages}
                                        </div>
                                        <div className="col-md-6">
                                            <Field
                                                name={"type"}
                                                component={FibaDropdown}
                                                dictCode={'PRODUCT_TYPE'}
                                                label={t("page.type1")}
                                                required={true}
                                            />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Field name={"name"} component={FibaInput} label={t("page.name")} required={true} />
                                        </div>
                                        <div className="col-md-6">
                                            <Field name={"name2"} component={FibaInput} label={t("page.name2")} required={true} />
                                        </div>
                                        <div className="col-md-6">
                                            <Field name={"price"} component={FibaInput} label={t("page.price")} required={true} />
                                        </div>
                                        <div className="col-md-6">
                                            <Field name={"rate"} component={FibaRating} label={t("page.rate")} required={true} />
                                        </div>
                                        {formRenderProps.valueGetter('type') !== 'event' && <>
                                            <div className="col-md-6">
                                                <Field name={"ingrediant"} component={FibaInput} label={t("page.ingrediant")} required={true} />
                                            </div>

                                            <div className="col-md-6">
                                                <Field name={"wait_duration"} component={FibaInput} label={t("page.wait_duration")} required={true} />
                                            </div>
                                            <div className="col-md-6">
                                                <Field name={"weigth"} component={FibaInput} label={t("page.weigth")} required={true} />
                                            </div>
                                        </>
                                        }
                                        {formRenderProps.valueGetter('type') === 'event' &&
                                            <>
                                                <div className="col-md-6">
                                                    <Field name={"capacity"} component={FibaInputNumber} label={t("page.capacity")} />
                                                </div>
                                                <div className="col-md-6">
                                                    <Field name={"date"} component={FibaDatePicker} label={t("page.date")} />
                                                </div>
                                                <div className="col-md-6">
                                                    <Field name={"web_url"} component={FibaInput} label={t("page.web_url")} placeholder={'https://google.com'} />
                                                </div>
                                                <div className="col-md-6">
                                                    <Field name={"social_link"} component={FibaInput}
                                                        label={t("page.social_link")} placeholder={'https://google.com'} />
                                                </div>
                                                <div className="col-md-6">
                                                    <Field name={"phone"} component={FibaInput} label={t("page.phone")} />
                                                </div>
                                            </>}
                                        <div className="col-md-6">
                                            <Field
                                                name={"description"}
                                                component={FibaTextArea}
                                                label={t("page.description")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </FormElement>
                        )}
                    ></Form>
                </Dialog>
            )}
        </>
    );
}
