// AUTHENTICATIONION URLS
export const API_AUTH_CHECK_USER = "/api/auth/check";
export const API_AUTH_LOGIN_USER = "/api/login";
export const API_AUTH_LOGIN_GOOGLE_USER = "/api/login/google";
export const API_AUTH_LOGOUT_USER = "/api/logout";
export const API_AUTH_USER_RESETPASS = "/api/reset-web";
export const API_AUTH_USER_FORGOTPASS = "/api/forgot-password-web";
export const API_AUTH_USER_CHANGEPASS = "/api/user/changePassword";

export const API_GOOGLE_AUTH_GEN_QR = "/api/google/generateqr";
export const API_GOOGLE_AUTH_CHECKCODE = "/api/google/checkcode";
export const API_GOOGLE_AUTH_USE = "/api/google/useGoogleAuth";
// UPLOAD IMAGE
export const API_UPLOAD_IMAGE = "/api/upload/image";

export const API_INST = "/api/inst";
export const API_GET_INST = "/api/get-inst";
export const API_BRANCH = "/api/branch";
export const API_PRODUCT = "/api/product";
export const API_WEB_PRODUCT = "/api/web-product";
export const API_ORDER = "/api/product-order";
export const API_USER_LIST = "/api/get-user";
export const API_USER_REGISTER = "/api/register-user";
export const API_USER_UPDATE = "/api/update-user";
export const API_USER_DELETE = "/api/delete-user";
export const API_USER_SHOW = "/api/show-user";
export const API_SEAT = "/api/branch-seat";
export const API_SEAT_QR = "/api/branch-seat-qr";
export const API_BRANCH_PRODUCT_ADD = "/api/product-branch-pack";
export const API_BRANCH_PRODUCTS_BRANCHCOUNT = "/api/products-branch-count";
export const API_BRANCH_PRODUCT = "/api/product-branch";
export const API_GET_CONST = "/api/getConst";
export const API_GET_STATISTIC = "/api/statistic";

//CUST NOTIF DATA URL
export const API_CUST_NOTIF_GET = "/api/get-send-notification";
export const API_CUST_NOTIF_DETAIL = "/api/get-detail-notification";
export const API_CUST_NOTIF_SEND = "/api/send-notification";
export const API_CUST_NOTIF_SENT_USERS = "/api/get-sent-notif-users";