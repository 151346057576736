import functions from '../../services/functions'
import {
    API_GOOGLE_AUTH_GEN_QR,
    API_GOOGLE_AUTH_CHECKCODE,
    API_GOOGLE_AUTH_USE,
    API_GET_STATISTIC
} from "../../constants/url.constants";
import ApiService from '../../services/ApiService';

export const getGoogleGenQr = async (data) => {
    let resp = functions.error;
    await ApiService("post", API_GOOGLE_AUTH_GEN_QR, data)
        .then((res) => {
            resp = res;
        },
            err => {
                resp.message = functions.getInfoRespError(err);
            })
    return resp;
};

export const checkGoogleCode = async (secret, code) => {
    let resp = functions.error;
    const data = {
        secret, code
    }
    await ApiService("post", API_GOOGLE_AUTH_CHECKCODE, data)
        .then((res) => {
            resp = res;
        })
        .catch((err) => resp.message = functions.getInfoRespError(err));
    return resp;
};

export const updateUseGoogleAuth = async (useGoogleAuth, id) => {
    let resp = functions.error;
    const data = {
        useGoogleAuth,
        id
    }
    await ApiService("post", API_GOOGLE_AUTH_USE, data)
        .then((res) => {
            resp = res;
        })
        .catch((err) => resp.message = functions.getInfoRespError(err));
    return resp;
};

export const getStatistic = async () => {
    let resp = functions.error;

    await ApiService("get", API_GET_STATISTIC)
        .then((res) => {
            resp = res;
        })
        .catch((err) => resp.message = functions.getInfoRespError(err));
    return resp;
};