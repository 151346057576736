import { FieldWrapper } from "@progress/kendo-react-form";
import * as React from "react";
import { Label, Error, Hint } from '@progress/kendo-react-labels';
import { Rating } from "@progress/kendo-react-inputs";

const FibaRating = fieldRenderProps => {
    const {
        validationMessage,
        touched,
        label,
        optional,
        id,
        valid,
        disabled,
        hint,
        ...others
    } = fieldRenderProps;
    const editorRef = React.useRef(null);
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';
    return <FieldWrapper>
        <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>
            {label}
        </Label>
        <Rating ref={editorRef} ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} valid={valid} id={id} disabled={disabled} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>;
};

export default FibaRating;