import React, { useEffect, useState } from "react";
import useFibaMsgBox from "../../hooks/useFibaMsgBox";
import useLoading from "../../hooks/useLoading";
import { getStatistic } from "./service";
import { Icon } from "@progress/kendo-react-common";

export default function Main() {
    const { showLoading } = useLoading();
    const { error } = useFibaMsgBox();
    const [data, setData] = useState({})

    const getStatistics = async () => {
        showLoading(true)
        const res = await getStatistic();
        showLoading(false)
        if (res.status === 'error') {
            error(res.message);
        } else {
            setData(res);
            console.log(res);
        }
    }

    useEffect(() => {
        getStatistics()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <style>
                {`
                    .ding-card {
                        width: 260px;
                        box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);
                        margin-top: 15px;
                        padding: 3px;
                    }
                    .ding-icon {
                        border-radius: 25px;
                        background-color: #99cc332e;
                        padding: 6px;
                    }
            `}
            </style>
            <div className="row">
                <div className="col-md-3">
                    <div className="k-card d-flex ding-card">
                        <div className="ding-icon">
                            <Icon name="user" themeColor={"primary"} size={"medium"} />
                        </div>
                        <div style={{ marginLeft: "20px", lineHeight: "20px" }}>
                            <div className="font-weight-bold">
                                {data.userCount}
                            </div>
                            <div className="text-muted">
                                <small>User count</small>
                            </div>

                        </div>
                    </div>
                    <div className="k-card d-flex ding-card">
                        <div className="ding-icon">
                            <Icon name="cart" themeColor={"primary"} size={"medium"} />
                        </div>
                        <div style={{ marginLeft: "20px", lineHeight: "20px" }}>
                            <div className="font-weight-bold">
                                {data.topProduct?.name}
                            </div>
                            <div className="text-muted">
                                <small>Top product</small>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="k-card">

                    </div>
                </div>
            </div>
        </>
    );
}
