import React, { useContext, useState, useEffect } from "react";
import { TOKEN_NAME } from "../constants/token.const";
import { API_AUTH_CHECK_USER, API_AUTH_LOGIN_USER, API_AUTH_LOGOUT_USER, API_AUTH_LOGIN_GOOGLE_USER } from "../constants/url.constants";
import functions from "../services/functions";
import LoadingSpin from "../components/LoadingSpin";
import useLoading from "../hooks/useLoading";
import { GetCookie, RemoveCookie, SetCookie } from "../services/CookieService";
import ApiService from "../services/ApiService";

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const { showLoading } = useLoading();

    let [auth, setAuth] = useState({
        user: null,
        insts: null,
        loading: true,
        perms: [],
        profile_url: null
    });

    async function checkUser() {
        showLoading(true);
        if (GetCookie(TOKEN_NAME) === undefined || GetCookie(TOKEN_NAME) === null || GetCookie(TOKEN_NAME) === "") {
            setAuth({
                ...auth,
                loading: false,
            });
            showLoading(false);
            return;
        }

        await ApiService("POST", API_AUTH_CHECK_USER)
            .then((res) => {
                if (res && res.data) {
                    res = res.data;
                }
                setAuth({
                    ...auth,
                    user: res,
                    loading: false,
                });
            })
            .catch((error) => {
                setAuth({ ...auth, loading: false });
            })
            .finally(() => {
                showLoading(false);
            });
    }

    async function login(username, password, pushToken) {
        let resp = functions.error;
        console.log(pushToken)
        await ApiService("POST", API_AUTH_LOGIN_USER, { login_name: username, password, pushToken })
            .then(async (res) => {
                localStorage.clear()
                resp = res;
                // setAuth((prev) => ({ ...prev, user: res.data }));
                SetCookie(TOKEN_NAME, res.meta?.token);
            },
                error => {
                    resp.message = functions.getInfoRespError(error);
                })
        return resp;
    }

    async function loginWithGoogle(username, password, secret, code) {
        let resp = functions.error;
        await ApiService("POST", API_AUTH_LOGIN_GOOGLE_USER, {
            username: username,
            password: password,
            secret,
            code
        })
            .then(async (res) => {
                localStorage.clear()
                resp = res
                setAuth((prev) => ({ ...prev, user: res.user }));
                SetCookie(TOKEN_NAME, res.token);
            },
                error => {
                    resp.message = functions.getInfoRespError(error);
                })
        return resp;
    }

    async function logout() {
        setAuth(auth);
        await ApiService("POST", API_AUTH_LOGOUT_USER)
            .then((res) => {
                RemoveCookie(TOKEN_NAME);
                auth = null;
                setAuth(auth);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function isAuth() {
        if (auth === null || auth?.user === null) return false;

        return true;
    }

    const value = {
        auth,
        login,
        logout,
        isAuth,
        loginWithGoogle,
        setAuth
    };

    useEffect(() => {
        checkUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <AuthContext.Provider value={value}>{!auth?.loading ? children : <LoadingSpin />}</AuthContext.Provider>;
}
