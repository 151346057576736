import functions from '../services/functions'
import {
    API_AUTH_USER_CHANGEPASS, API_BRANCH, API_BRANCH_PRODUCT, API_BRANCH_PRODUCTS_BRANCHCOUNT, API_BRANCH_PRODUCT_ADD, API_CUST_NOTIF_DETAIL, API_CUST_NOTIF_GET, API_CUST_NOTIF_SEND, API_CUST_NOTIF_SENT_USERS, API_GET_CONST, API_GET_INST, API_INST, API_ORDER, API_PRODUCT, API_SEAT, API_SEAT_QR, API_UPLOAD_IMAGE, API_USER_DELETE, API_USER_LIST, API_USER_REGISTER, API_USER_SHOW, API_USER_UPDATE, API_WEB_PRODUCT
} from "../constants/url.constants";
import { GetTimeCookie, SetTimeCookie } from '../services/CookieService';
import { DING_DICT } from '../constants/token.const';
import ApiService from '../services/ApiService';


export const changePass = async (oldpassword, newpassword, confirmpassword, userid) => {
    let resp = functions.error;
    await ApiService("post", API_AUTH_USER_CHANGEPASS, {
        oldpassword: oldpassword,
        newpassword: newpassword,
        newpassword2: confirmpassword,
        userid: userid
    }).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const uploadImage = async (requestData) => {
    let resp = functions.error;
    await ApiService("POST", API_UPLOAD_IMAGE, requestData)
        .then((res) => {
            resp = res;
        })
        .catch((error) => {
            resp.message = functions.getInfoRespError(error);
        });
    return resp;
}

export const getInsts = async (item) => {
    let resp = functions.error;
    await ApiService("get", API_GET_INST, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const getInstDetail = async (id) => {
    let resp = functions.error;
    await ApiService("get", `${API_INST}/${id}`).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const addInst = async (item) => {
    let resp = functions.error;
    await ApiService("post", `${API_INST}`, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const updateInst = async (item, id) => {
    let resp = functions.error;
    await ApiService("put", `${API_INST}/${id}`, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const deleteInst = async (id) => {
    let resp = functions.error;
    await ApiService("delete", `${API_INST}/${id}`,).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};
export const getSeats = async (item) => {
    let resp = functions.error;
    await ApiService("get", API_SEAT, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const getSeatDetail = async (id) => {
    let resp = functions.error;
    await ApiService("get", `${API_SEAT}/${id}`).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const addSeat = async (item) => {
    let resp = functions.error;
    await ApiService("post", `${API_SEAT}`, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const updateSeat = async (item, id) => {
    let resp = functions.error;
    await ApiService("put", `${API_SEAT}/${id}`, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const deleteSeat = async (id) => {
    let resp = functions.error;
    await ApiService("delete", `${API_SEAT}/${id}`,).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const getSeatQr = async (id) => {
    let resp = functions.error;
    await ApiService("get", `${API_SEAT_QR}/${id}`,).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const getBranch = async (item) => {
    let resp = functions.error;
    await ApiService("get", API_BRANCH, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const getBranchDetail = async (id) => {
    let resp = functions.error;
    await ApiService("get", `${API_BRANCH}/${id}`).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const addBranch = async (item) => {
    let resp = functions.error;
    await ApiService("post", `${API_BRANCH}`, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const updateBranch = async (item) => {
    let resp = functions.error;
    await ApiService("put", `${API_BRANCH}/${item.id}`, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};
export const addUser = async (item) => {
    let resp = functions.error;
    await ApiService("post", `${API_USER_REGISTER}`, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const updateUser = async (item) => {
    let resp = functions.error;
    await ApiService("post", `${API_USER_UPDATE}/${item.id}`, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const deleteUser = async (item) => {
    let resp = functions.error;
    await ApiService("post", `${API_USER_DELETE}`, {id: item}).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const deleteBranch = async (id) => {
    let resp = functions.error;
    await ApiService("delete", `${API_BRANCH}/${id}`,).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const getProduct = async (item) => {
    let resp = functions.error;
    await ApiService("get", API_WEB_PRODUCT, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const getProductDetail = async (id) => {
    let resp = functions.error;
    await ApiService("get", `${API_PRODUCT}/${id}`).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const addProduct = async (item) => {
    let resp = functions.error;
    await ApiService("post", `${API_PRODUCT}`, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const updateProduct = async (item) => {
    let resp = functions.error;
    await ApiService("put", `${API_PRODUCT}/${item.id}`, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const deleteProduct = async (id) => {
    let resp = functions.error;
    await ApiService("delete", `${API_PRODUCT}/${id}`,).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

// Бүтээгдэхүүн салбарын холбоос салгах
export const deleteProductBranch = async (item) => {
    let resp = functions.error;
    await ApiService("delete", `${API_BRANCH_PRODUCT}/${item.product_id}`, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const addProductBranch = async (item) => {
    let resp = functions.error;
    await ApiService("post", `${API_BRANCH_PRODUCT_ADD}`, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};


export const getOrders = async (item) => {
    let resp = functions.error;
    await ApiService("get", API_ORDER, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const getOrderDetail = async (id) => {
    let resp = functions.error;
    await ApiService("get", `${API_ORDER}/${id}`).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const cancelOrder = async (order_id) => {
    let resp = functions.error;
    await ApiService("post", `${API_ORDER}-cancel`, {order_id}).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const finishOrder = async (order_id) => {
    let resp = functions.error;
    await ApiService("post", `${API_ORDER}-finish`, {order_id}).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const payConfirmOrder = async (order_id) => {
    let resp = functions.error;
    await ApiService("post", `${API_ORDER}-pay`, {order_id}).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const getUsers = async (item) => {
    let resp = functions.error;
    await ApiService("get", API_USER_LIST, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const getUserDetail = async (id) => {
    let resp = functions.error;
    await ApiService("get", `${API_USER_SHOW}`, { id }).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const setCountProductsBranch = async (item) => {
    let resp = functions.error;
    await ApiService("post", `${API_BRANCH_PRODUCTS_BRANCHCOUNT}`, item).then((res) => {
        resp = res;
    },
        (err) => {
            resp.message = functions.getInfoRespError(err)
        })
    return resp;
};

export const getDicts = async (genDictMainType, requestData = {}) => {
    if (genDictMainType) {
        if (typeof genDictMainType === 'object') {
            genDictMainType = genDictMainType.dict_code;
        }
        let resp = functions.error;
        if (await GetTimeCookie(`${DING_DICT}.${genDictMainType}`) != null) {
            resp = await GetTimeCookie(`${DING_DICT}.${genDictMainType}`);
            return resp;
        } else {
            requestData.dict_code = genDictMainType;
            await ApiService("get", API_GET_CONST, requestData).then(
                res => {
                    resp = res;
                    SetTimeCookie(`${DING_DICT}.${genDictMainType}`, res)
                }).catch((err) => {
                    resp.message = functions.getInfoRespError(err);
                })
            return resp;
        }
    }
}

export const DetailNotif = async (item) => {
    let resp = functions.error;
    await ApiService("POST", API_CUST_NOTIF_DETAIL, item)
        .then((res) => {
            resp = res;
        })
        .catch((error) => {
            resp.message = functions.getInfoRespError(error);
        });
    return resp;
};

export const sendNotif = async (item) => {
    let resp = functions.error;
    await ApiService("POST", API_CUST_NOTIF_SEND, item)
        .then((res) => {
            resp = res;
        })
        .catch((error) => {
            resp.message = functions.getInfoRespError(error);
        });
    return resp;
};

export const getNotifList = async (item) => {
    let resp = functions.error;
    await ApiService("POST", API_CUST_NOTIF_GET, item)
        .then((res) => {
            resp = res;
        })
        .catch((error) => {
            resp.message = functions.getInfoRespError(error);
        });
    return resp;
};

export const sentNotifUsers = async (item) => {
    let resp = functions.error;
    await ApiService("POST", API_CUST_NOTIF_SENT_USERS, item)
        .then((res) => {
            resp = res;
        })
        .catch((error) => {
            resp.message = functions.getInfoRespError(error);
        });
    return resp;
};