export const Operator = {
    eq: 'eq',
    neq: 'neq',
    startswith: 'startswith',
    endswith: 'endswith',
    isnull: 'isnull',
    isnotnull: 'isnotnull',
    gte: 'gte',
    gt: 'gt',
    lte: 'lte',
    lt: 'lt',
    contains: 'contains'
}