import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { addUser, getUserDetail, updateUser } from "../services";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import useLoading from "../../hooks/useLoading";
import useFibaMsgBox from "../../hooks/useFibaMsgBox";
import { formValidationFunc } from "../../services/functions";
import FibaInput from "../../components/input/FibaCustomInput";

export default function UserDetail(props) {
    const { showLoading } = useLoading();
    const { error, success, confirm } = useFibaMsgBox();
    const { t } = useTranslation();
    const [defaultValue, setDefaultVal] = useState({});
    const [show, setShow] = useState(false);
    const id = props?.modalState?.data?.id;
    const params = useParams();
    const {auth} = useAuth();

    const getDetail = async () => {
        if (id && id !== 'null') {
            showLoading(true);
            const res = await getUserDetail(id);
            if (res?.status === "error") {
                error(res.message);
                return;
            }
            setDefaultVal(res);
            setShow(true);
            showLoading(false);
        } else {
            setShow(true);
        }
    }

    const onSubmit = async (data) => {
        const isConfirmed = await confirm("Та хадгалахдаа итгэлтэй байна уу?");
        if (isConfirmed) {

            let res = {
                ...data,
                inst_id: params?.id ?? auth.user?.inst_id,
                branch_id: props?.branch_id ?? null,
                password: '123456789'
                // usernametype: data.usernametype ? (data.usernametype.value ? data.usernametype.value : data.usernametype) : '',
            };
            showLoading(true);
            if (id && id !== 'null') {
                res = await updateUser(res, id);
                showLoading(false);
                if (res?.status === "error") {
                    error(res.message);
                } else {
                    success(t("text.successsave"));
                    props.closeModal();
                }
            } else {
                res.login_name = res.email;
                res = await addUser(res);
                showLoading(false);
                if (res?.status === "error") {
                    error(res.message);
                } else {
                    success(res?.message);
                    props.closeModal();
                }
            }
        }

    };

    const validations = {
        fname: {
            required: true,
            maxLength: 50,
            title: t("page.name"),
        }
    };

    const formValidation = (values) => {
        return formValidationFunc(values, validations, t);
    };

    useEffect(() => {
        getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {show && (
                <Dialog title={id ? t("text.update") : t("text.add")} onClose={props.closeModal} width={'80vw'} height={'90%'}>
                    <Form
                        onSubmit={onSubmit}
                        initialValues={defaultValue}
                        validator={formValidation}
                        ignoreModified={(id && id !== 'null') ? true : false}
                        render={(formRenderProps) => (
                            <FormElement>
                                <DialogActionsBar layout={"end"}>
                                    <button type="button" className="k-button" onClick={formRenderProps.onFormReset}>
                                        {t("text.cancel")}
                                    </button>
                                    <button type="submit" className="k-button k-primary" disabled={!formRenderProps.allowSubmit}>
                                        {t("text.save")}
                                    </button>
                                </DialogActionsBar>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Field name={"lname"} component={FibaInput} label={t("page.lname")} required={true} />
                                        </div>
                                        <div className="col-md-6">
                                            <Field name={"fname"} component={FibaInput} label={t("page.name")} required={true} />
                                        </div>
                                        <div className="col-md-6">
                                            <Field name={"email"} component={FibaInput} label={t("page.email")} required={true} />
                                        </div>
                                        {/* <div className="col-md-6">
                                            <Field name={"password"} component={FibaInput} label={t("page.password")} required={true} />
                                        </div> */}
                                        {/* <div className="col-md-6">
                                            <Field
                                                name={"type"}
                                                component={FibaDropdown}
                                                label={t("page.type")}
                                                required={true}
                                                dictCode={'USER_TYPE'}
                                            />
                                        </div> */}
                                    </div>
                                </div>
                            </FormElement>
                        )}
                    ></Form>
                </Dialog>
            )}
        </>
    );
}
