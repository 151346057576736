import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage} from 'firebase/messaging';
const REPLACE_WITH_YOUR_VAPID_KEY = "BDRH4QKJKfkfIGZqXTViMvJZEx4a33aIfOFqYmlUIhJs_BhdgdFQ4eYkWabmL845RKg-SUtFfGB12LZpbymhWic";

//....

export const requestForToken = async () => {
  let token = '';
  await getToken(messaging, { vapidKey: REPLACE_WITH_YOUR_VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        token = currentToken;
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
    return token;
};

const firebaseConfig = {
  apiKey: "AIzaSyAiHsHawb8Hkwydkhe9kA5G4CkxSKwChHU",
  authDomain: "ding-app-b5ca0.firebaseapp.com",
  projectId: "ding-app-b5ca0",
  storageBucket: "ding-app-b5ca0.appspot.com",
  messagingSenderId: "569044918541",
  appId: "1:569044918541:web:83c33b36663f29c4911044",
  measurementId: "G-4VX79TZL0T"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));