import React from 'react';
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DateInput } from '@progress/kendo-react-dateinputs';

const FibaOperatorEditor = (props) => {
    const { filterType } = props;
    if (filterType === 'boolean') {
        return;
    }

    const value = props.operators.find(item => item.operator === props.operator) || null;
    const operatorChange = (event) => {
        props.onChange({ value: props.value, operator: event.target.value.operator, syntheticEvent: event.syntheticEvent })
    }
    return (
        <DropDownList
            value={value}
            onChange={operatorChange}
            data={props.operators}
            textField="text"
        />
    );
}

export const CustomFilterUi = (props) => {
    const { firstFilterProps } = props;
    const inputChange = (value, e) => {
        firstFilterProps.onChange({ value: value, operator: firstFilterProps.operator, syntheticEvent: e });
    }

    return (
        <div>
            <FibaOperatorEditor {...firstFilterProps} />
            {/* <DropDownList data={firstFilterProps.operators} defaultValue={firstFilterOperator} valueField={'operator'} textField='text'/> */}
            {
                firstFilterProps.filterType === 'date' ?
                    <DateInput
                        value={firstFilterProps.value}
                        onChange={(e) => { inputChange(e.target.value, e); }}
                        format={'yyyy/MM/dd'}
                        formatPlaceholder={{
                            year: "yyyy",
                            month: "MM",
                            day: "dd",
                        }}
                    /> :
                    <Input value={firstFilterProps.value || ''} onChange={(e) => { inputChange(e.target.value, e); }} />
            }
        </div>
    );
};