import * as React from 'react';
import { GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { CustomFilterUi } from "./CustomFilterUi.jsx";
import { customFilterOperators } from './FilterOperators.js';

export const FibaFilter = (props) => {
    let temp;
    temp = {
        ...props,
        filterOperators: customFilterOperators,
        hideSecondFilter: true
    };
    if (props?.column.filter === 'date') {
        return <GridColumnMenuFilter {...temp} filterUI={CustomFilterUi} expanded={true}/>
    } else {
        return <GridColumnMenuFilter {...temp} expanded={true}/>
    }
    // return <GridColumnMenuFilter {...temp} filterUI={CustomFilterUi} expanded={true}/>
    
// };
};