import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "@progress/kendo-react-dialogs";
import { getOrderDetail, payConfirmOrder } from "../services";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import functions from "../../components/grid/functions";
import ListScreen from "../../components/grid/grid";
import { ColumnMenu } from "../../components/grid/filter-components/columnMenu";
import { pagerSettings } from "../../components/grid/constant";
import { process } from "@progress/kendo-data-query";
import { useToasts } from "react-toast-notifications";
import useLoading from "../../hooks/useLoading";
import useFibaMsgBox from "../../hooks/useFibaMsgBox";

const createDataState = (dataState) => {
    return {
        result: process([], dataState),
        dataState: dataState,
    };
};

export default function OrderDetail({ modalState, ...props }) {
    const { showLoading } = useLoading();
    const { error, confirm } = useFibaMsgBox();
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const { id } = useParams();
    const initPerPage = 20;
    const [orders, setOrders] = useState({ data: [], total: 0 });
    let initialState = createDataState({ take: initPerPage, skip: 0 });
    const [dataState, setDataState] = React.useState(initialState.dataState);
    const { addToast } = useToasts();
    const [requestData, setRequestData] = useState({
        orders: [],
        perPage: initPerPage,
        page: 1,
        filters: [],
        withClient: 1,
    });

    const calculateTotal = (datalength) => {
        let total = requestData.perPage * (requestData.page - 1);
        return total + datalength + 1;
    }

    const getDetail = async () => {
        if (modalState.data) {
            showLoading(true);
            const res = await getOrderDetail(modalState.data.id);
            if (res?.status === "error") {
                error(res.message);
            } else {
                if (res) {
                    setOrders({ data: res, total: calculateTotal(res.length) });
                }
            }
            showLoading(false);
        }
        setShow(true);
    }

    const dataStateChange = async (event) => {
        const reqData = await functions.getListRequest(event.dataState);
        setRequestData(reqData);
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
    };

    const grid = (
        <Grid
            style={{ height: "100%" }}
            {...dataState}
            data={orders}
            total={orders.total ?? 0}
            onDataStateChange={dataStateChange}
            pageable={pagerSettings}
            sortable={{ mode: "multiple" }}
        >
            <GridColumn field="id" title={t("page.id")} filter="text" columnMenu={ColumnMenu} />
            <GridColumn field="product.name" title={t("page.product")} filter="text" />
            <GridColumn field="product_type.name" title={t("page.product_type")} filter="text" />
            <GridColumn field="count" title={t("page.count1")} filter="text" columnMenu={ColumnMenu} />
            <GridColumn field="unit_price" title={t("page.unit_price")} filter="text" columnMenu={ColumnMenu} />
            <GridColumn field="sum_price" title={t("page.sum_price")} filter="text" columnMenu={ColumnMenu} />
        </Grid>
    );

    const payOrder = async () => {
        const isconfirmed = await confirm('Төлбөр баталгаажсан төлөвт оруулахдаа итгэлтэй байна уу?');
        if (isconfirmed) {
            showLoading(true);
            const res = await payConfirmOrder(modalState.data.id);
            if (res?.status === "error") {
                addToast(res.message, { appearance: "error" });
            } else {
                getDetail();
                addToast(t("text.successupdate"), { appearance: "success" });
            }
            showLoading(false);
        }
    }

    useEffect(() => {
        getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestData]);

    return (
        <>
            {
                show && (
                    <Dialog title={id ? t("text.update") : t("text.add")} onClose={props.closeModal} width={'80vw'} height={'90%'}>
                        <div className="d-flex justify-content-end mb-2">
                            <button
                                className="k-button k-primary"
                                title={t('page.pay_confirm')}
                                onClick={() => payOrder()}
                            >
                                {t('page.pay_confirm')}
                            </button>
                        </div>
                        <ListScreen grid={grid}></ListScreen>
                    </Dialog>
                )
            }
        </>
    );
}
