import React, { useState, useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { cancelOrder, finishOrder, getOrders } from "../services";
import OrderDetail from "./OrderDetail";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import FibaDropdown from "../../components/input/FibaDropdown";
import useLoading from "../../hooks/useLoading";
import useFibaMsgBox from "../../hooks/useFibaMsgBox";
import { pagerSettings } from "../../components/grid/constant";
import Breadcrumb from "../../components/model/Breadcrumb";
import ListScreen from "../../components/grid/grid";
import functions from "../../components/grid/functions";
import { ColumnMenu } from "../../components/grid/filter-components/columnMenu";

const createDataState = (dataState) => {
    return {
        result: process([], dataState),
        dataState: dataState,
    };
};

const OrderColumnCell = ({ cancel, edit,finishOrders, ...props }) => {
    const { t } = useTranslation();
    const {
        dataItem
    } = props;
    return <td className="k-command-cell">
        {edit && <button
            className="k-primary k-button k-grid-edit-command"
            onClick={() => edit(dataItem)}
            title={t(props.editTitle ? props.editTitle : 'text.edit')}
        >
            <i className="fiba-i-edit" />
        </button>}
        {cancel && <button
            className="k-button"
            title={props.deleteTitle ? props.deleteTitle : t('text.order_cancel')}
            onClick={() => cancel(dataItem)}
        >
            <i className="fiba-i-delete" />
        </button>}
        {finishOrders && <button
            className="k-button"
            title={t('page.recieve_cust')}
            onClick={() => finishOrders(dataItem)}
        >
            <i className="la la-send" />
        </button>}
    </td>;
};

const statusData = [
    { value: 0, name: 'Төлбөр төлөлт хүлээгдэж байна.' },
    { value: 1, name: 'Төлбөр баталгаажсан.' },
    { value: 2, name: 'Хэрэглэгч хүлээн авсан' },
    { value: 3, name: 'Цуцлагдсан' }
];

export default function OrderList(props) {
    const { addToast } = useToasts();
    const { showLoading } = useLoading();
    const [orders, setOrders] = useState({ data: [], total: 0 });
    const initPerPage = 20;
    const { confirm } = useFibaMsgBox();
    const { t } = useTranslation();
    const [requestData, setRequestData] = useState({
        orders: [],
        perPage: initPerPage,
        page: 1,
        filters: []
    });
    const [modal, setModal] = React.useState({
        state: false,
        data: null
    });
    let initialState = createDataState({ take: initPerPage, skip: 0 });
    const [dataState, setDataState] = React.useState(initialState.dataState);

    const calculateTotal = (datalength) => {
        let total = requestData.perPage * (requestData.page - 1);
        return total + datalength + 1;
    }

    const fetch = async () => {
        showLoading(true);
        //requestData.filters.push({ cond: "=", value: true, field: "isadmin" });
        const res = await getOrders(requestData);
        if (res?.status === "error") {
            addToast(res.message, { appearance: "error" });
        } else {
            if (res) {
                setOrders({ data: res, total: calculateTotal(res.length) });
            }
        }
        showLoading(false);
    };

    const onSubmit = (data) => {
        if (data.statusid || data.statusid === 0) {
            let isInclude = false;
            for (let index = 0; index < requestData.filters.length; index++) {
                const element = requestData.filters[index];
                if (element.field === 'statusid') {
                    isInclude = true;
                    element.cond = '=';
                    element.value = data.statusid;
                }
            }
            if (!isInclude) {
                requestData.filters.push({ cond: "=", value: data.statusid, field: "statusid" });
            }
            setRequestData({ ...requestData })
        }
    }

    const cancelOrderData = async (id) => {
        showLoading(true);
        const res = await cancelOrder(id);
        if (res?.status === "error") {
            addToast(res.message, { appearance: "error" });
        } else {
            fetch();
            addToast(t("text.successupdate"), { appearance: "success" });
        }
        showLoading(false);
    };
    
    const finishOrderData = async (id) => {
        showLoading(true);
        const res = await finishOrder(id);
        if (res?.status === "error") {
            addToast(res.message, { appearance: "error" });
        } else {
            fetch();
            addToast(t("text.successupdate"), { appearance: "success" });
        }
        showLoading(false);
    };

    const openUpdateModal = (dataItem) => {
        setModal({ state: true, data: dataItem });
    };

    const dataStateChange = async (event) => {
        const reqData = await functions.getListRequest(event.dataState);
        setRequestData(reqData);
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
    };

    useEffect(() => {
        fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestData]);

    const remove = async (dataItem) => {
        const isConfirmed = await confirm("Захиалга цуцлахдаа итгэлтэй байна уу?");
        if (isConfirmed) {
            cancelOrderData(dataItem.id);
        } else {
            console.log("Declined.");
        }
    };

    const finishOrders = async (dataItem) => {
        const isConfirmed = await confirm("Харилцагчид хүлээлгэж өгсөн төлөвт оруулахдаа итгэлтэй байна уу?");
        if (isConfirmed) {
            finishOrderData(dataItem.id);
        } else {
            console.log("Declined.");
        }
    };

    const choosedData = (event) => {
        if (props.chooseData) {
            props.chooseData(event.dataItem);
        }
    };

    const CommandCell = (props) => <OrderColumnCell
        {...props}
        edit={openUpdateModal}
        cancel={remove}
        finishOrders={finishOrders}
        editField={"nonInEdit"}
        keyField={"userid"}
        deleteTitle={t('page.cancel_order')}
    />;

    const grid = (
        <Grid
            style={{ height: "77vh" }}
            {...dataState}
            data={orders}
            total={orders.total ?? 0}
            onDataStateChange={dataStateChange}
            pageable={pagerSettings}
            sortable={{ mode: "multiple" }}
            onRowDoubleClick={choosedData}
        >
            <GridColumn field="id" title={t("page.id")} filter="text" columnMenu={ColumnMenu} />
            <GridColumn field="seat_id" title={t("page.seat")} filter="numeric" columnMenu={ColumnMenu} />
            <GridColumn field="price" title={t("page.price")} filter="numeric" columnMenu={ColumnMenu} />
            <GridColumn field="status_name" title={t("text.status")} filter="text" columnMenu={ColumnMenu} />
            <GridColumn field="branch.name" title={t("page.branch")} />
            <GridColumn field="created_at" title={t("page.created_at")} format="{0:dd/MM/yyyy}" filter="date" columnMenu={ColumnMenu} />
            {!props.chooseData && <GridColumn width="150" cell={CommandCell} />}
        </Grid>
    );

    return (
        <>
            {!props.chooseData && <Breadcrumb
                title={t("page.order")}
            />}

            <Form
                onSubmit={onSubmit}
                render={(formRenderProps) => (
                    <FormElement>
                        <div className="row">
                            <div className="col-md-3">
                                <Field
                                    name={"statusid"}
                                    component={FibaDropdown}
                                    label={t("text.status")}
                                    data={statusData}
                                    required={true}
                                />
                            </div>
                            <div className="col-md-3"
                                style={{ marginTop: '34px' }}>
                                <button type="submit" className="k-button k-primary" disabled={!formRenderProps.allowSubmit}>
                                    {t("text.search")}
                                </button>
                            </div>
                        </div>
                    </FormElement>
                )}
            />
            <div className="mt-2"></div>
            <ListScreen grid={grid}></ListScreen>
            {modal.state && (
                <OrderDetail
                    reload={fetch}
                    closeModal={() => {
                        setModal({ state: false });
                        fetch();
                    }}
                    modalState={modal}
                />
            )}
        </>
    );
}
