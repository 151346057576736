import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import bgLogin from "../../assets/img/back03.jpg";
import { useTranslation } from "react-i18next";
import logo from "../../assets/img/logos/logo_photo_white_500x86.png";
import { Button } from "@progress/kendo-react-buttons";
import { API_AUTH_USER_FORGOTPASS } from "../../constants/url.constants";
import { Input } from "@progress/kendo-react-inputs";
import functions from "../../services/functions";
import useLoading from "../../hooks/useLoading";
import ApiService from "../../services/ApiService";
import { LangService } from "../../services/LangService";

export default function ForgotPassword() {
    const { t, i18n } = useTranslation();
    const emailRef = useRef();
    const { showLoading } = useLoading();
    const [validEmail, setValidEmail] = useState();
    const [result, setResult] = useState({ type: "", msg: "" });

    const forgotPasswordClick = () => {
        if (validEmail) {
            setResult({ type: "loading", msg: null });
            showLoading(true);
            ApiService("post", API_AUTH_USER_FORGOTPASS, { email: emailRef.current.value })
                .then((res) => {
                    setResult({ type: "success", msg: t('text.confirmSendMail') });
                })
                .catch((err) => {
                    setResult({ type: "error", msg: functions.getInfoRespError(err) });
                })
                .finally(() => {
                    showLoading(false);
                });
        }
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        // history.push(document.location.pathname + '?lng=' + lng)
    };

    const handleValidation = (e) => {
        if (e.target.name === "email") {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(String(emailRef.current.value).toLowerCase())) setValidEmail(true);
            else setValidEmail(false);
        }
    };

    useEffect(() => {
        const save = {
            backgroundColor: document.body.style.backgroundColor,
            backgroundSize: document.body.style.backgroundSize,
            display: document.body.style.display,
            justifyContent: document.body.style.justifyContent,
            alignItems: document.body.style.alignItems,
            flexDirection: document.body.style.flexDirection,
            fontFamily: document.body.style.fontFamily,
            backdropFilter: document.body.style.backdropFilter,
        };

        document.body.style.backgroundColor = "rgba(255, 0, 0, 0.5)";
        document.body.style.backgroundSize = "cover";
        document.body.style.display = "flex";
        document.body.style.justifyContent = "center";
        document.body.style.alignItems = "center";
        document.body.style.flexDirection = "column";
        document.body.style.fontFamily = "Montserrat";
        // document.body.style.backdropFilter= 'blur(25px) saturate(100%)';

        return () => {
            document.body.style.backgroundColor = save.backgroundColor;
            document.body.style.backgroundSize = save.backgroundSize;
            document.body.style.display = save.display;
            document.body.style.justifyContent = save.justifyContent;
            document.body.style.alignItems = save.alignItems;
            document.body.style.flexDirection = save.flexDirection;
            document.body.style.fontFamily = save.fontFamily;
            // document.body.style.backdropFilter= save.backdropFilter
        };
    }, []);

    return (
        <>
            <style>
                {`
                    body {
                        background-image: url('${bgLogin}');
                    }
                `}
            </style>
            <div style={{ position: "absolute", top: 30, right: 40 }}>
                {LangService &&
                    LangService.map((lang) => {
                        if (i18n.language !== lang.lng) {
                            return (
                                <a
                                    href="#/"
                                    key={lang.lng}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        changeLanguage(lang.lng);
                                    }}
                                >
                                    <img src={lang.icon} alt="" height={30} />
                                </a>
                            );
                        } else {
                            return ""
                        }
                    })}
            </div>
            <div className="main-wrapper">
                <Helmet>
                    <title>SAINSCORE - Зээлийн мэдээллийн сан</title>
                </Helmet>
                {/* <a href="/orange/applyjob/joblist" className="btn btn-primary apply-btn">Apply Job</a> */}
                <div className="container" style={{ height: "100vh" }}>
                    <div
                        style={{
                            backgroundColor: "transparent",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            height: "100%",
                            textAlign: "center",
                            maxWidth: "400px"
                        }}
                    >
                        <div className="row justify-content-center">
                            <div className="col-12 text-center mb-4">
                                <img src={logo} height={70} alt="sainscore logo" />
                            </div>
                            <div className="card col-md-10 py-4 mx-1">
                                <strong className="font-weight-bold h3" style={{ width: '100%' }}>
                                    {t("login.forgot_title")}
                                </strong>
                                {result.type !== "" ? (
                                    <div className={`alert alert-${result.type} text-center`} role="alert">
                                        {result.msg}
                                    </div>
                                ) : (
                                    <div style={{ width: '100%' }}>
                                        <p className="account-subtitle">
                                            {t("login.forgot_enter")}
                                        </p>
                                        <div className="form-group">
                                            {/* <label style={{ color: "#fff" }}>{t("login.email")}</label> */}
                                            <Input
                                                onChange={(e) => handleValidation(e)}
                                                type="email"
                                                name="email" ref={emailRef}
                                                style={{
                                                    width: "100%",
                                                }}
                                                required={true}
                                                label={t("login.email")}
                                            />

                                            {/* <input className={`form-control ${validEmail ? "is-valid" : "is-invalid"}`} style={{ borderRadius: "10px" }} /> */}
                                        </div>
                                        <div className="form-group text-center">
                                            <Button
                                                primary={true}
                                                onClick={forgotPasswordClick}
                                                disabled={!validEmail}
                                            >
                                                {t("login.confirm")}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                <p>
                                    <Link to="/login" style={{ color: "#15005f" }}>
                                        {t("login.forgot_back")}
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
