import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { addBranch, getBranchDetail, updateBranch, uploadImage } from "../services";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import GoogleMapReact from 'google-map-react';
import marker from '../../assets/img/logos/markers.png'
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import OrderList from "../order/OrderList";
import SeatList from "../seat/SeatList";
import ProductList from "../product/ProductList";
import UserList from "../user/UserList";
import useLoading from "../../hooks/useLoading";
import useFibaMsgBox from "../../hooks/useFibaMsgBox";
import { formValidationFunc } from "../../services/functions";
import FibaInput from "../../components/input/FibaCustomInput";
import { FibaTextArea } from "../../components/input/FibaTextArea";

export default function BranchDetail(props) {
    const { showLoading } = useLoading();
    const { error, success, confirm } = useFibaMsgBox();
    const { t } = useTranslation();
    const [defaultValue, setDefaultVal] = useState({});
    const id = props?.modalState?.data?.id;
    const params = useParams();
    const [show, setShow] = useState(false);
    const [picture, setPicture] = useState({});
    const [imgData, setImgData] = useState({});
    const [myMarker, setMyMarker] = useState({});
    const [selected, setSelected] = React.useState(0);
    const defaultProps = {
        center: {
            lat: props?.modalState?.data ? +props?.modalState?.data.lat : 47.9159233,
            lng: props?.modalState?.data ? +props?.modalState?.data.long : 106.9230347
        },
        zoom: props?.modalState?.data?.id ? 14 : 12
    };
    const markerStyle = {
        position: "absolute",
        top: "100%",
        left: "50%",
        transform: "translate(-50%, -100%)"
    };

    const AnyReactComponent = () => <img style={markerStyle} src={marker} alt="pin" />;
    const getDetail = async () => {
        if (id) {
            showLoading(true);
            const res = await getBranchDetail(id);
            if (res?.status === "error") {
                error(res.message);
            }
            setDefaultVal(res);
            setMyMarker({
                lat: res.lat,
                lng: res.long
            })
            showLoading(false);
        }
        setShow(true);
    }

    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    const onChangePicture = (e, field) => {
        if (e.syntheticEvent.target.files[0]) {
            picture[field] = e.syntheticEvent.target.files[0];
            setPicture({ ...picture });

            let reader = new FileReader();
            reader.readAsDataURL(e.syntheticEvent.target.files[0]);
            reader.addEventListener("load", () => {
                imgData[field] = reader.result;
                setImgData({ ...imgData });
            });
        }

        let reader = new FileReader();
        reader.readAsDataURL(e);
        reader.addEventListener("load", () => {
            imgData[field] = reader.result;
            setImgData({ ...imgData });
        });
    };

    const onSubmit = async (data) => {
        const isConfirmed = await confirm("Та хадгалахдаа итгэлтэй байна уу?");
        if (isConfirmed) {
            if (!myMarker.lat) {
                error('Салбарын байршлаа сонгоно уу.');
                return;
            }
            let res = {
                ...data,
                inst_id: params?.id,
                lat: myMarker.lat,
                long: myMarker.lng
                // usernametype: data.usernametype ? (data.usernametype.value ? data.usernametype.value : data.usernametype) : '',
            };
            const formData = new FormData();
            showLoading(true);
            for (const key in picture) {
                if (Object.hasOwnProperty.call(picture, key)) {
                    const element = picture[key];
                    if (element) {
                        formData.append('image', element, element?.name);
                        const resp = await uploadImage(formData);
                        if (resp?.status === "error") {
                            showLoading(false);
                            error(resp.message);
                            return;
                        } else {
                            res[key] = resp.data?.image;
                        }
                    }
                }
            }
            if (id) {
                res = await updateBranch(res, id);
            } else {
                res = await addBranch(res);
            }
            showLoading(false);
            if (res?.status === "error") {
                error(res.message);
            } else {
                success(t("text.successsave"));
                props.closeModal();
            }
        }

    };

    const validations = {
        name: {
            required: true,
            maxLength: 50,
            title: t("page.name"),
        }
    };

    const formValidation = (values) => {
        return formValidationFunc(values, validations, t);
    };

    const mapClickLocation = ({ lat, lng }) => {
        setMyMarker({
            lat,
            lng
        })
    }

    useEffect(() => {
        getDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {show && (
                <Dialog title={id ? t("text.update") : t("text.add")} onClose={props.closeModal} width={'80vw'} height={'90%'}>
                    <TabStrip selected={selected} onSelect={handleSelect}>
                        <TabStripTab title={`${t('text.moreIfno')}`}>
                            <Form
                                onSubmit={onSubmit}
                                initialValues={defaultValue}
                                validator={formValidation}
                                ignoreModified={id ? true : false}
                                render={(formRenderProps) => (
                                    <FormElement>
                                        <DialogActionsBar layout={"end"}>
                                            <button type="button" className="k-button" onClick={formRenderProps.onFormReset}>
                                                {t("text.cancel")}
                                            </button>
                                            <button type="submit" className="k-button k-primary" disabled={!formRenderProps.allowSubmit}>
                                                {t("text.save")}
                                            </button>
                                        </DialogActionsBar>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Field name={"name"} component={FibaInput} label={t("page.name")} required={true} />
                                                </div>
                                                <div className="col-md-6">
                                                    <Field
                                                        name={"phone"}
                                                        required={true}
                                                        component={FibaInput}
                                                        label={t("page.phone")}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <Field
                                                        name={"work_start_time"}
                                                        component={FibaInput}
                                                        label={t("page.work_start_time")}
                                                        required={true} />
                                                </div>
                                                <div className="col-md-6">
                                                    <Field
                                                        name={"work_end_time"}
                                                        component={FibaInput}
                                                        label={t("page.work_end_time")}
                                                        required={true} />
                                                </div>
                                                <div className="col-md-6">
                                                    <Field
                                                        name={"address"}
                                                        required={true}
                                                        component={FibaTextArea}
                                                        label={t("page.address")}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    {(!picture['logo_url'] && defaultValue.logo_url) && <img src={process.env.REACT_APP_SERVER_URL + defaultValue.logo_url} alt="Ding logo" height={100} />}
                                                    {(picture['logo_url']) && <img src={window.URL.createObjectURL(picture['logo_url'])} alt="Ding logo" height={100} />}
                                                    <Field
                                                        type={"file"}
                                                        accept="image/*"
                                                        name={"logo_file"}
                                                        component={FibaInput}
                                                        label={t("page.logo_url")}
                                                        onChange={(e) => onChangePicture(e, 'logo_url')}
                                                    />
                                                </div>

                                                <div className="col-md-12" style={{ height: '400px' }}>
                                                    Салбарын байршлаа сонгоно уу.
                                                    <GoogleMapReact
                                                        bootstrapURLKeys={{
                                                            key: "AIzaSyB-7sTwcuc5u8XxWpomHEjFEkEr2jfNozo",
                                                            language: "mn",
                                                            region: "MN"
                                                        }}
                                                        options={{
                                                            mapTypeId: 'roadmap',
                                                            mapTypeControl: true,
                                                            fullscreenControl: false
                                                        }}
                                                        defaultCenter={defaultProps.center}
                                                        defaultZoom={defaultProps.zoom}
                                                        onClick={mapClickLocation}
                                                        yesIWantToUseGoogleMapApiInternals
                                                    >
                                                        <AnyReactComponent
                                                            lat={myMarker.lat}
                                                            lng={myMarker.lng}
                                                            text="My Marker"
                                                        />
                                                    </GoogleMapReact>
                                                </div>
                                            </div>
                                        </div>
                                    </FormElement>
                                )}
                            ></Form>
                        </TabStripTab>
                        <TabStripTab title={t('page.order')} disabled={!(id && id !== 'null')} >
                            <OrderList instid={params?.id} branchid={id}></OrderList>
                        </TabStripTab>
                        <TabStripTab title={t('page.seat')} disabled={!(id && id !== 'null')} >
                            <SeatList instid={params?.id} branchid={id}></SeatList>
                        </TabStripTab>
                        <TabStripTab title={t('page.product')} disabled={!(id && id !== 'null')} >
                            <ProductList instid={params?.id} branchid={id}></ProductList>
                        </TabStripTab>
                        <TabStripTab title={t('page.admin')} disabled={!(id && id !== 'null')} >
                            <UserList instid={params?.id} branchid={id}></UserList>
                        </TabStripTab>
                    </TabStrip>
                </Dialog>
            )}
        </>
    );
}
