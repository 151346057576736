export const Fields = {
    filterEqOperator: 'grid.filterEqOperator',
    filterNotEqOperator: 'grid.filterNotEqOperator',
    filterStartsWithOperator: 'grid.filterStartsWithOperator',
    filterEndsWithOperator: 'grid.filterEndsWithOperator',
    filterIsNullOperator: 'grid.filterIsNullOperator',
    filterIsNotNullOperator: 'grid.filterIsNotNullOperator',
    filterGteOperator: 'grid.filterGteOperator',
    filterGtOperator: 'grid.filterGtOperator',
    filterLteOperator: 'grid.filterLteOperator',
    filterLtOperator: 'grid.filterLtOperator',
    filterAfterOrEqualOperator: 'grid.filterAfterOrEqualOperator',
    filterAfterOperator: 'grid.filterAfterOperator',
    filterBeforeOperator: 'grid.filterBeforeOperator',
    filterBeforeOrEqualOperator: 'grid.filterBeforeOrEqualOperator',
    filterContainsOperator: 'grid.filterContainsOperator'
}