import React, { useState, useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { deleteUser, getUsers } from "../services";
import UserDetail from "./UserDetail";
import useLoading from "../../hooks/useLoading";
import useFibaMsgBox from "../../hooks/useFibaMsgBox";
import functions from "../../components/grid/functions";
import { MyCommandCell } from "../../components/grid/myCommandCell";
import { pagerSettings } from "../../components/grid/constant";
import { ColumnMenu } from "../../components/grid/filter-components/columnMenu";
import Breadcrumb from "../../components/model/Breadcrumb";
import ListScreen from "../../components/grid/grid";

const createDataState = (dataState) => {
    return {
        result: process([], dataState),
        dataState: dataState,
    };
};

export default function UserList(props) {
    const { addToast } = useToasts();
    const { showLoading } = useLoading();
    const [insts, setUsers] = useState();
    const initPerPage = 20;
    const { confirm } = useFibaMsgBox();
    const { t } = useTranslation();
    const [requestData, setRequestData] = useState({
        orders: [],
        perPage: initPerPage,
        page: 1,
        filters: [],
        withClient: 1,
    });
    let initialState = createDataState({ take: initPerPage, skip: 0 });
    const [dataState, setDataState] = React.useState(initialState.dataState);
    const [modal, setModal] = React.useState({
        state: false,
        data: null
    });
    const setAddModal = () => {
        setModal({state: true, data: null});
        // history.push('/user/null');
    };
    const fetch = async () => {
        showLoading(true);
        //requestData.filters.push({ cond: "=", value: true, field: "isadmin" });
        if (props?.instid) {
            requestData.inst_id = props?.instid;
        }
        if (props?.branchid) {
            requestData.branch_id = props?.branchid;
        } else {
            if (props?.instid) {
                requestData.type = 'admin';
            }
        }
        const res = await getUsers(requestData);
        if (res?.status === "error") {
            addToast(res.message, { appearance: "error" });
        } else {
            if (res) {
                setUsers(res);
            }
        }
        showLoading(false);
    };

    const deleteUsr = async (userid) => {
        showLoading(true);
        const res = await deleteUser(userid);
        if (res?.status === "error") {
            addToast(res.message, { appearance: "error" });
        } else {
            fetch();
            addToast(t("text.successdelete"), { appearance: "success" });
        }
        showLoading(false);
    };

    const openUpdateModal = (dataItem) => {
        setModal({state: true, data: dataItem});
        // history.push('/user/' + dataItem.id);
    };

    const dataStateChange = async (event) => {
        const reqData = await functions.getListRequest(event.dataState);
        setRequestData(reqData);
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
    };

    useEffect(() => {
        fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestData]);

    const remove = async (dataItem) => {
        const isConfirmed = await confirm("Устгахдаа итгэлтэй байна уу?");
        if (isConfirmed) {
            deleteUsr(dataItem.id);
        } else {
            console.log("Declined.");
        }
    };

    const choosedData = (event) => {
        if (props.chooseData) {
            props.chooseData(event.dataItem);
        }
    };

    const CommandCell = (props) => <MyCommandCell {...props} edit={openUpdateModal} remove={remove} editField={"nonInEdit"} keyField={"userid"} />;

    const grid = (
        <Grid
            style={{ height: "77vh" }}
            {...dataState}
            data={insts}
            total={insts?.total}
            onDataStateChange={dataStateChange}
            pageable={pagerSettings}
            sortable={{ mode: "multiple" }}
            onRowDoubleClick={choosedData}
        >
            <GridColumn field="id" title={t("page.id")} filter="text" columnMenu={ColumnMenu} />
            <GridColumn field="fname" title={t("page.name")} filter="text" columnMenu={ColumnMenu} />
            <GridColumn field="email" title={t("page.email")} filter="text" columnMenu={ColumnMenu} />
            <GridColumn field="type" title={t("page.type")} filter="text" columnMenu={ColumnMenu} />
            <GridColumn field="created_at" title={t("page.created_at")} format="{0:dd/MM/yyyy}" filter="date" columnMenu={ColumnMenu} />
            {!props.chooseData && <GridColumn width="100" cell={CommandCell} />}
        </Grid>
    );

    return (
        <>
            {!props.chooseData && <Breadcrumb
                title={t("page.user")}
                addbutton={setAddModal}
                addbtntitle={`${t("page.user")} ${t("text.add")}`}
            />}

            <ListScreen grid={grid}></ListScreen>
            {modal.state && (
                <UserDetail
                    reload={fetch}
                    closeModal={() => {
                        setModal({state: false});
                        fetch();
                    }}
                    branch_id={props?.branchid}
                    modalState={modal}
                />
            )}
        </>
    );
}
