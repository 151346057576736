import * as React from "react";
import { useTranslation } from "react-i18next";

export const CommandCell = props => {
    const { t } = useTranslation();
    const {
        dataItem,
        optionalIcon
    } = props;

    return <td className="k-command-cell">
        {props.edit && <button
            className="k-primary k-button k-grid-edit-command"
            onClick={() => props.edit(dataItem)}
            title={t(props.editTitle ? props.editTitle : 'text.edit')}
        >
            <i className="fiba-i-edit" />
        </button>}
        {props.remove &&  <button
            className="k-button k-grid-remove-command"
            title={props.deleteTitle ? props.deleteTitle : t('text.delete')}
            onClick={() => props.remove(dataItem)}
        >
            <i className="fiba-i-delete" />
        </button>}
        {props.optional &&  <button
            className="k-button k-grid-remove-command"
            title={props.optionalTitle ? props.optionalTitle : t('text.optional')}
            onClick={() => props.optional(dataItem)}
        >
            <i className={optionalIcon ? optionalIcon : "fiba-i-hmore"} />
        </button>}
    </td>;
};