import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { addSeat, getSeatDetail, updateSeat } from "../services";
import { useEffect } from "react";
import FibaInput from "../../components/input/FibaCustomInput";
import QRCode from "react-qr-code";
import { toPng } from 'html-to-image';
import { Button } from "@progress/kendo-react-buttons";
import useLoading from "../../hooks/useLoading";
import useFibaMsgBox from "../../hooks/useFibaMsgBox";
import { formValidationFunc } from "../../services/functions";

export default function SeatDetail(props) {
    const { showLoading } = useLoading();
    const { error, success, confirm } = useFibaMsgBox();
    const { t } = useTranslation();
    const [defaultValue, setDefaultVal] = useState({});
    const id = props?.modalState?.data?.id;
    const [show, setShow] = useState(false);
    const ref = useRef(null)

    const getDetail = async () => {
        if (id) {
            showLoading(true);
            const res = await getSeatDetail(id);
            if (res?.status === "error") {
                error(res.message);
            }
            setDefaultVal(res);
            showLoading(false);
        }
        setShow(true);
    }

    const onButtonClick = () => {
        if (ref.current === null) {
            return
        }
        showLoading(true);
        toPng(ref.current, { cacheBust: true, })
            .then((dataUrl) => {
                const link = document.createElement('a')
                console.log(defaultValue)
                link.download = 'ding-' + defaultValue.number + '.png'
                link.href = dataUrl
                link.click()
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => showLoading(false))
    }

    const onSubmit = async (data) => {
        const isConfirmed = await confirm("Та хадгалахдаа итгэлтэй байна уу?");
        if (isConfirmed) {

            let res = {
                number: "" + data.number,
                branch_id: props.branchid
                // usernametype: data.usernametype ? (data.usernametype.value ? data.usernametype.value : data.usernametype) : '',
            };
            showLoading(true);
            if (id) {
                res = await updateSeat(res, id);
            } else {
                res = await addSeat(res);
            }
            showLoading(false);
            if (res?.status === "error") {
                error(res.message);
            } else {
                success(t("text.successsave"));
                props.closeModal();
            }
        }

    };

    const validations = {
        number: {
            required: true,
            title: t("page.number"),
        }
    };

    const formValidation = (values) => {
        return formValidationFunc(values, validations, t);
    };


    useEffect(() => {
        getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {show && (
                <Dialog title={id ? t("text.update") : t("text.add")} onClose={props.closeModal} width={'80vw'} height={'90%'}>
                    <Form
                        onSubmit={onSubmit}
                        initialValues={defaultValue}
                        validator={formValidation}
                        ignoreModified={id ? true : false}
                        render={(formRenderProps) => (
                            <FormElement>
                                <DialogActionsBar layout={"end"}>
                                    <button type="button" className="k-button" onClick={formRenderProps.onFormReset}>
                                        {t("text.cancel")}
                                    </button>
                                    <button type="submit" className="k-button k-primary" disabled={!formRenderProps.allowSubmit}>
                                        {t("text.save")}
                                    </button>
                                </DialogActionsBar>
                                <div className="modal-body">
                                    <div className="row justify-content-center">
                                        <div className="col-md-6">
                                            <Field name={"number"} component={FibaInput} label={t("page.number")} required={true} />
                                            {
                                                id && <>
                                                    <div ref={ref}>
                                                        <style>
                                                            {`svg>path{
                                                                border: none;
                                                            }`}
                                                        </style>
                                                        <QRCode
                                                            title={'page.number'}
                                                            size={200}
                                                            fgColor={'black'}
                                                            value={`${process.env.NODE_ENV === 'production' ?
                                                                process.env.REACT_APP_SERVER_URL :
                                                                process.env.REACT_APP_SERVER_URL_TEST}/api/branch-seat-qr/${defaultValue.qr_code}`
                                                            }
                                                        />
                                                    </div>
                                                    <Button className="ml-2 mt-2" onClick={onButtonClick} type="button">Download</Button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </FormElement>
                        )}
                    ></Form>
                </Dialog>
            )}

        </>
    );
}
