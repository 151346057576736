import * as React from "react";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";

const FibaInputNumber = (fieldRenderProps) => {
    const { validationMessage, visited, required, label, fieldInfo, ...others } = fieldRenderProps;
    let value = null;
    if (fieldRenderProps.value) {
        value = parseFloat(fieldRenderProps.value);
    }
    return (
        <label className="k-form-field">
            <span>
                {label}
                {required && <span className="k-required">*</span>}
            </span>
            <NumericTextBox {...others} required={required} value={value} />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
            {fieldInfo && <span className="k-field-info">*{fieldInfo}</span>}
        </label>
    );
};
export default FibaInputNumber;
