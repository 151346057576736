import React from "react";
import {
    LocalizationProvider,
    loadMessages
} from "@progress/kendo-react-intl";
import enMessages from "../../components/grid/lang/en.json"
import mnMessages from "../../components/grid/lang/mn.json"
import './style.css'
import { useTranslation } from 'react-i18next'

const ListScreen = (props) => {
    const { i18n } = useTranslation();
    if (i18n.language === 'en') {
        loadMessages(enMessages, "en-US");
    } else {
        loadMessages(mnMessages, "mn-MN");
    }

    return (
        <>

            <LocalizationProvider language={i18n.language === 'en' ? 'en-US' : 'mn-MN'}>
                {/* <Tooltip openDelay={100} position="right" anchorElement="target"> */}
                    {props.grid ? props.grid : props.children}
                {/* </Tooltip> */}
            </LocalizationProvider>

        </>
    );
};

export default ListScreen;
