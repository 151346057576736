import { useContext } from "react";
import ConfirmContext from "../store/FibaMsgContext";
import { CONFIRM_BOX, HIDE_CONFIRM, SHOW_CONFIRM, SUCCESS_BOX, INFO_BOX, WARNING_BOX, ERROR_BOX } from "../store/MsgReducer";

let resolveCallback;
function useFibaMsgBox() {
    const [msgBoxState, dispatch] = useContext(ConfirmContext);
    const onConfirm = () => {
        closeConfirm();
        resolveCallback(true);
    };

    const onCancel = () => {
        closeConfirm();
        resolveCallback(false);
    };
    
    const confirm = (text) => {
        dispatch({
            type: SHOW_CONFIRM,
            payload: {
                text,
                type: CONFIRM_BOX,
                icon: 'fiba-i-confirmation'
            },
        });
        return new Promise((res, rej) => {
            resolveCallback = res;
        });
    };

    const success = (text) => {
        dispatch({
            type: SHOW_CONFIRM,
            payload: {
                text,
                type: SUCCESS_BOX,
                icon: 'fiba-i-success'
            },
        });
        return new Promise((res, rej) => {
            resolveCallback = res;
        });
    };

    const info = (text) => {
        dispatch({
            type: SHOW_CONFIRM,
            payload: {
                text,
                type: INFO_BOX,
                icon: 'fiba-i-warning'
            },
        });
        return new Promise((res, rej) => {
            resolveCallback = res;
        });
    };

    const warn = (text) => {
        dispatch({
            type: SHOW_CONFIRM,
            payload: {
                text,
                type: WARNING_BOX,
                icon: 'fiba-i-warning'
            },
        });
        return new Promise((res, rej) => {
            resolveCallback = res;
        });
    };

    const error = (text) => {
        dispatch({
            type: SHOW_CONFIRM,
            payload: {
                text,
                type: ERROR_BOX,
                icon: 'fiba-i-error'
            },
        });
        return new Promise((res, rej) => {
            resolveCallback = res;
        });
    };

    const closeConfirm = () => {
        dispatch({
            type: HIDE_CONFIRM,
        });
    };

    return { confirm, success, info, warn, error, onConfirm, onCancel, msgBoxState };
}

export default useFibaMsgBox;
