import * as React from "react";
import { useTranslation } from "react-i18next";

export const MyCommandCell = props => {
    const { t } = useTranslation();
    const {
        dataItem
    } = props;
    if (!props.dataItem) {
        return <td className="k-command-cell"></td>
    }
    const inEdit = dataItem[props?.editField];
    const isNewItem = dataItem[props.keyField ? props.keyField : 'id'] === undefined;
    return inEdit ? <td className="k-command-cell">
        <button 
            className="k-button k-grid-save-command"
            title={t('text.save')}
            onClick={() => isNewItem ? props.add(dataItem) : props.update(dataItem)}>
            <i className="fiba-i-save" />
            {/* {isNewItem ? "Add" : "Update"} */}
        </button>
        <button className="k-button k-grid-cancel-command" title={t('text.cancel')} onClick={() => isNewItem ? props.discard(dataItem) : props.cancel(dataItem)}>
            <i className="fiba-i-cancel" />
            {/* {isNewItem ? "Discard" : "Cancel"} */}
        </button>
    </td> : <td className="k-command-cell">
        {(props.edit && props.edit != null) && <button
            className="k-primary k-button k-grid-edit-command"
            onClick={() => props.edit(dataItem)}
            title={t(props.editTitle ? props.editTitle : 'text.edit')}
        >
            <i className="fiba-i-edit" />
        </button>}
        {props.remove &&  <button
            className="k-button"
            title={props.deleteTitle ? props.deleteTitle : t('text.delete')}
            onClick={() => props.remove(dataItem)}
        >
            <i className="fiba-i-delete" />
        </button>}
    </td>;
};