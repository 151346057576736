import axios from 'axios'
import { GetCookie, SetCookie, RemoveCookie } from './CookieService'
import { TOKEN_NAME, NOT_AUTH, CACHE_ROUTE, EXPIRY_PASS } from "../constants/token.const";

const axiosInstance = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SERVER_URL : process.env.REACT_APP_SERVER_URL_TEST
})

const ApiService = (method, url, data, headers = {}) => {
    const options = {
        method: method,
        url: url,
        ...(data?._download && { responseType: 'blob' }),
        headers: {
            'limit': '0kb',
            'Content-Type': 'application/json', //'application/octet-stream'
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + GetCookie(TOKEN_NAME),
            ...headers
        }
    }

    localStorage.setItem('sessionTimer', new Date());
    if (method.toLowerCase() === 'get')
        options.params = data
    else
        options.data = data

    const onSuccess = function (response) {
        // if (basicOptions !== null) return response
        RemoveCookie(NOT_AUTH);
        if (response?.data && response?.data.next_page_url) {
            response.data.total = response.data.to + 1;
        } else if (response?.data && response?.data.prev_page_url) {
            response.data.total = response.data.to;
        } else if (response?.data && response.data.data) {
            response.data.total = response?.data?.data?.length;
        }
        return response.data
    }

    const onError = function (error) {
        console.error(error);
        if (error.response && error.response.status) {
            if (error.response.status === 401) {
                // const history = useHistory();
                if (!GetCookie(NOT_AUTH)) {
                    const path = window.location.pathname;
                    if (path !== '/login' && !path.includes("resetPassword")
                        && !path.includes("forgotpassword")) {
                        SetCookie(CACHE_ROUTE, path);
                        window.location = '/login';
                    }
                }
                SetCookie(NOT_AUTH, 401);
            } else if (error.response.status === 303) {
                // window.location = '/changepass';
                window.location.replace('/changepass');
                SetCookie(EXPIRY_PASS, 303);
            }
        }

        return Promise.reject(error.response || error.message)
    }

    return axiosInstance(options).then(onSuccess).catch(onError)
}

export default ApiService
