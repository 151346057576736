import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { useToasts } from "react-toast-notifications";
import { sendNotif, DetailNotif, sentNotifUsers } from "../services";
import useFibaMsgBox from "../../hooks/useFibaMsgBox";
import useLoading from "../../hooks/useLoading";
import { FibaTextArea } from "../../components/input/FibaTextArea";
import FibaInput from "../../components/input/FibaCustomInput";

export default function NotifDetailScreen(props) {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { confirm } = useFibaMsgBox();
    const { showLoading } = useLoading();
    const [defaultValue, setDefaultVal] = useState(props.data ? props.data : {});

    const onSubmit = async (data) => {
        const isConfirmed = await confirm("Та илгээхдээ итгэлтэй байна уу?");
        if (isConfirmed) {
            showLoading(true);
            let resp;

            if (!data.id) {
                data.is_all = 1;
                resp = await sendNotif(data);
            }
            showLoading(false);
            if (resp?.status === "error") {
                addToast(resp.message, { appearance: "error" });
            } else {
                if (resp.id) {
                    data.id = resp.id;
                }
                addToast(t("text.successsent"), { appearance: "success" });
                props.reload();
                props.closeModal();
            }
        }
    };

    const fetch = async () => {
        showLoading(true);
        setDefaultVal(null);
        const res = await DetailNotif({ id: props.data.id });
        if (res?.status === "error") {
            addToast(res.message, { appearance: "error" });
        } else {
            setDefaultVal(res);
        }
        showLoading(false);
    };

    const sentUsersfetch = async () => {
        if (!props.data.is_all) {
            showLoading(true);
            setDefaultVal(null);
            const res = await sentNotifUsers({ id: props.data.id });
            if (res?.status === "error") {
                addToast(res.message, { appearance: "error" });
            } else {
                if (res.data && res.data.length > 0) {
                    for (let index = 0; index < res.data.length; index++) {
                        const element = res.data[index];
                        if (element.cust_userid) {
                            element.userinfo = `${element.cust_userid} - ${element.user.lastname} ${element.user.firstname}`;
                        }
                    }
                }
            }
            showLoading(false);
        }
    };

    useEffect(() => {
        if (props.callType !== "Send") {
            fetch();
            sentUsersfetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {props.modalState && (
                <Dialog title={props.callType === "Send" ? t("text.notifsend") : t("text.notifdetail")} onClose={props.closeModal} width={1000} height={500} actions={[{ text: "OK" }]}>
                    {defaultValue && (
                        <Form
                            onSubmit={onSubmit}
                            initialValues={defaultValue}
                            render={(formRenderProps) => (
                                <FormElement>
                                    <DialogActionsBar layout={"end"}>
                                        <button type="button" className="k-button" onClick={props.closeModal}>
                                            {t("text.cancel")}
                                        </button>
                                        {props.callType === "Send" && (
                                            <button type="submit" className="k-button k-primary" disabled={!formRenderProps.allowSubmit}>
                                                {t("text.notifsend")}
                                            </button>
                                        )}
                                    </DialogActionsBar>
                                    <style>{`.k-listview-content {width: 100%; !important}`}</style>
                                    <div className="row mx-3 justify-content-center">
                                        <div className="col-md-6">
                                            <Field name={"title"} component={FibaInput} label={t("global.title")} required={true} disabled={props.callType === "Send" ? false : true} />
                                            <Field
                                                name={"description"}
                                                type="text"
                                                component={FibaTextArea}
                                                label={t("global.description")}
                                                rows={12}
                                                required={true}
                                                disabled={props.callType === "Send" ? false : true}
                                            />
                                        </div>
                                    </div>
                                </FormElement>
                            )}
                        ></Form>
                    )}
                </Dialog>
            )}
        </>
    );
}
