import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { addInst, getInstDetail, updateInst, uploadImage } from "../services";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import BranchList from "../branch/BranchList";
import UserList from "../user/UserList";
import OrderList from "../order/OrderList";
import ProductList from "../product/ProductList";
import FibaDropdown from "../../components/input/FibaDropdown";
import useLoading from "../../hooks/useLoading";
import useFibaMsgBox from "../../hooks/useFibaMsgBox";
import { formValidationFunc } from "../../services/functions";
import FibaInput from "../../components/input/FibaCustomInput";
import { FibaTextArea } from "../../components/input/FibaTextArea";

export default function InstDetail() {
    const { showLoading } = useLoading();
    const { error, success, confirm } = useFibaMsgBox();
    const { t } = useTranslation();
    const [defaultValue, setDefaultVal] = useState({});
    let { id, tab } = useParams();
    const history = useHistory();
    const [imgData, setImgData] = useState({});
    const [picture, setPicture] = useState({});

    const getDetail = async () => {
        if (id && id !== 'null') {
            showLoading(true);
            const res = await getInstDetail(id);
            if (res?.status === "error") {
                error(res.message);
            }
            setDefaultVal(res);
            showLoading(false);
        } else {
            setDefaultVal({ id: 'null' })
        }
    }

    const onSubmit = async (data) => {
        const isConfirmed = await confirm("Та хадгалахдаа итгэлтэй байна уу?");
        if (isConfirmed) {

            let res = {
                ...data,
                // usernametype: data.usernametype ? (data.usernametype.value ? data.usernametype.value : data.usernametype) : '',
            };
            const formData = new FormData();
            showLoading(true);
            for (const key in picture) {
                if (Object.hasOwnProperty.call(picture, key)) {
                    const element = picture[key];
                    if (element) {
                        formData.append('image', element, element?.name);
                        const resp = await uploadImage(formData);
                        if (resp?.status === "error") {
                            showLoading(false);
                            error(resp.message);
                            return;
                        } else {
                            res[key] = resp.data?.image;
                        }
                    }
                }
            }

            if (id && id !== 'null') {
                res = await updateInst(res, id);
            } else {
                res = await addInst(res);
            }
            showLoading(false);
            if (res?.status === "error") {
                error(res.message);
            } else {
                success(t("text.successsave"));
                if (res.id) {
                    id = res.id;
                }
                history.push(`/inst/${id}`);
            }
        }

    };

    const validations = {
        name: {
            required: true,
            maxLength: 50,
            title: t("page.name"),
        }
    };

    const formValidation = (values) => {
        return formValidationFunc(values, validations, t);
    };

    const [selected, setSelected] = React.useState(tab ? +tab : 0);

    const handleSelect = (e) => {
        setSelected(e.selected);
        history.push(`/inst/${id}/${e.selected}`);
    };

    const onChangePicture = (e, field) => {
        if (e.syntheticEvent.target.files[0]) {
            picture[field] = e.syntheticEvent.target.files[0];
            setPicture({ ...picture });

            let reader = new FileReader();
            reader.readAsDataURL(e.syntheticEvent.target.files[0]);
            reader.addEventListener("load", () => {
                imgData[field] = reader.result;
                setImgData({ ...imgData });
            });
        }

        let reader = new FileReader();
        reader.readAsDataURL(e);
        reader.addEventListener("load", () => {
            imgData[field] = reader.result;
            setImgData({ ...imgData });
        });
    };

    useEffect(() => {
        getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TabStrip selected={selected} onSelect={handleSelect}>
                <TabStripTab title={`${t('page.inst')} ${t('text.more')}`}>
                    {defaultValue.id && (

                        <Form
                            onSubmit={onSubmit}
                            initialValues={defaultValue}
                            validator={formValidation}
                            ignoreModified={id && id !== 'null' ? true : false}
                            render={(formRenderProps) => (
                                <FormElement>
                                    <DialogActionsBar layout={"end"}>
                                        <button type="button" className="k-button" onClick={formRenderProps.onFormReset}>
                                            {t("text.cancel")}
                                        </button>
                                        <button type="submit" className="k-button k-primary" disabled={!formRenderProps.allowSubmit}>
                                            {t("text.save")}
                                        </button>
                                    </DialogActionsBar>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Field name={"name"} component={FibaInput} label={t("page.name")} required={true} />
                                            </div>
                                            <div className="col-md-12">
                                                <Field
                                                    name={"service_type"}
                                                    component={FibaDropdown}
                                                    dictCode={'SERVICE_TYPE'}
                                                    label={t("page.service_type")}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                {(!picture['logo_url'] && defaultValue.logo_url) && <img src={process.env.REACT_APP_SERVER_URL + defaultValue.logo_url} alt="Ding logo" height={100} />}
                                                {(picture['logo_url']) && <img src={window.URL.createObjectURL(picture['logo_url'])} alt="Ding logo" height={100} />}
                                                <Field
                                                    type={"file"}
                                                    accept="image/*"
                                                    name={"logo_file"} component={FibaInput}
                                                    label={t("page.logo_url")}
                                                    onChange={(e) => onChangePicture(e, 'logo_url')}
                                                />
                                            </div>
                                            <div className="col-md-6 mt-2">
                                                {(!picture['cover_url'] && defaultValue.cover_url) && <img src={process.env.REACT_APP_SERVER_URL + defaultValue.cover_url} alt="Ding logo" height={100} />}
                                                {picture['cover_url'] && <img src={window.URL.createObjectURL(picture['cover_url'])} alt="Ding logo" height={100} />}
                                                <Field
                                                    accept="image/*"
                                                    name={"cover_file"}
                                                    type={"file"}
                                                    component={FibaInput}
                                                    label={t("page.cover_url")}
                                                    onChange={(e) => onChangePicture(e, 'cover_url')}
                                                />
                                            </div>
                                            <div className="col-md-6 py-3">
                                                <Field
                                                    name={"description"}
                                                    component={FibaTextArea}
                                                    label={t("page.description")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </FormElement>
                            )}
                        ></Form>
                    )}
                </TabStripTab>
                <TabStripTab title={t('page.branch')} disabled={!(id && id !== 'null')} >
                    <BranchList instid={id} />
                </TabStripTab>
                <TabStripTab title={t('page.order')} disabled={!(id && id !== 'null')} >
                    <OrderList instid={id}></OrderList>
                </TabStripTab>
                <TabStripTab title={t('page.product')} disabled={!(id && id !== 'null')} >
                    <ProductList instid={id}></ProductList>
                </TabStripTab>
                <TabStripTab title={t('page.admin')} disabled={!(id && id !== 'null')} >
                    <UserList instid={id}></UserList>
                </TabStripTab>
            </TabStrip>

        </>
    );
}
