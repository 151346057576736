import React from 'react'
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom'
import Main from '../main/pages/Main'
import Login from '../main/pages/Login'
import DrawerRouterContainer from '../main/layouts/DrawerRouterContainer'
import PrivateRoute from './PrivateRoute'
import ChangePass from '../main/pages/ChangePass'
import GoogleAuthenticator from '../main/pages/SettingsGoogleAuthenticator'
import ForgotPassword from '../main/pages/ForgotPassword'
import ResetPassword from '../main/pages/ResetPassword'
import InstList from '../pages/inst/InstList'
import InstDetail from '../pages/inst/InstDetail'
import TransactionList from '../pages/transaction/TransactionList'
import UserList from '../pages/user/UserList'
import UserDetail from '../pages/user/UserDetail'
import OrderList from '../pages/order/OrderList'
import OrderDetail from '../pages/order/OrderDetail'
import error404 from '../main/pages/error/error404'
import { useAuth } from '../contexts/AuthContext'
import NotifList from '../pages/notif/Notif'

const Routes = () => {
    const { isAuth } = useAuth()
    return (
        <>
            <BrowserRouter>
                <DrawerRouterContainer>
                    <Switch>
                        <PrivateRoute exact={true} path="/">
                            {!isAuth() ? <Redirect to="/login" /> : <Redirect to="/main" />}
                        </PrivateRoute>
                        <PrivateRoute exact={true} path="/main" component={Main} />
                        <PrivateRoute exact={true} path="/changepass" component={ChangePass} />
                        <PrivateRoute exact={true} path="/google-auth" component={GoogleAuthenticator} />
                        <Route exact={true} path="/login" component={Login} />
                        <Route exact={true} path="/forgotpassword" component={ForgotPassword} />
                        <Route exact={true} path="/resetPassword/:token" component={ResetPassword} />
                        <PrivateRoute exact={true} path="/inst" component={InstList} />
                        <PrivateRoute exact={true} path="/inst/:id" component={InstDetail} />
                        <PrivateRoute exact={true} path="/inst/:id/:tab" component={InstDetail} />
                        <PrivateRoute exact={true} path="/transactions" component={TransactionList} />
                        <PrivateRoute exact={true} path="/user" component={UserList} />
                        <PrivateRoute exact={true} path="/user/:id" component={UserDetail} />
                        <PrivateRoute exact={true} path="/orders" component={OrderList} />
                        <PrivateRoute exact={true} path="/orders/:id" component={OrderDetail} />
                        <PrivateRoute exact={true} path="/notification" component={NotifList} />
                        <Route exact={true} component={error404} />
                    </Switch>
                </DrawerRouterContainer>
            </BrowserRouter>
        </>
    )
}
export default Routes;