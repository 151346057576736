import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { useTranslation } from 'react-i18next'
import withValueField from "./FibaDropdownWithValue";
import { getDicts } from "../../pages/services";
const DropDownListWithValueField = withValueField(DropDownList);
const FibaDropdown = React.forwardRef((fieldRenderProps, ref) => {
    const { validationMessage, visited, required, label, fieldInfo, textField, valueField, defaultValue, dictCode, filterable, ...others } = fieldRenderProps;
    const [data, setData] = React.useState(fieldRenderProps.data ? fieldRenderProps.data.slice() : []);
    const [defaultItem, setDefaultVal] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();
    const filterData = (filter) => {
        const data1 = fieldRenderProps.data.slice();
        return filterBy(data1, filter);
    };
    /**
     * dictCode props оор орж ирсэн үед дата дүүргэнэ.
     */
    const getDictsData = async () => {
        if (dictCode) {
            setLoading(true);
            const res = await getDicts(dictCode);
            setLoading(false);
            if (res.message !== 'error') {
                setData(res.data ? res.data : res);
            } else {
                console.error('Тогтмолын бүртгэл авахад алдаа гарлаа.');
            }
        }
    }

    const filterChange = (event) => {
        setData(filterData(event.filter));
    };
    let value = {};
    if (fieldRenderProps.value && typeof fieldRenderProps.value === 'object') {
        value = fieldRenderProps.value[valueField];
    } else {
        value = fieldRenderProps.value
        if (!value && value !== 0) {
            value = null;
        }
        // console.log(value)
    }

    React.useEffect(() => {
        const dt = {};
        dt[textField ? textField : 'name'] = t('text.choose');
        getDictsData();
        setDefaultVal(dt)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // console.log(fieldRenderProps.data)
    const listNoDataRender = (element) => {
        const noData = (
            <h4
                style={{
                    fontSize: "1em",
                }}
            >
                <span
                    className="k-icon k-i-warning"
                    style={{
                        fontSize: "2.5em",
                    }}
                />
                <br />
                <br />
                Хоосон.
            </h4>
        );
        return React.cloneElement(element, { ...element.props }, noData);
    };
    return (
        <div className="k-form-field">
            <span>
                {label}
                {required && <span className="k-required">*</span>}
            </span>
            <div>
                <DropDownListWithValueField
                    valueField={valueField ? valueField : 'value'}
                    textField={textField ? textField : 'name'}
                    defaultItem={defaultItem}
                    data={data}
                    {...others}
                    required={required}
                    onFilterChange={filterChange}
                    value={value}
                    loading={loading}
                    filterable={filterable}
                    listNoDataRender={listNoDataRender}
                />
            </div>
            {fieldInfo && <span className="k-field-info">*{fieldInfo}</span>}
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
});
export default FibaDropdown;