import * as React from 'react';
import { GridColumnMenuFilter, GridColumnMenuCheckboxFilter, GridColumnMenuSort, GridColumnMenuGroup } from '@progress/kendo-react-grid';
import { customFilterOperators } from "./FilterOperators.js";
import { FibaFilter } from './FibaFilter.jsx';

export const ColumnMenu = (props) => {
    return <FibaFilter {...props}/>
};

export const ColumnMenuCheckboxFilter = props => {
    const data = [];
    if (props.filterData) {
        props.filterData.forEach(element => {
            element[props.column.field] = element[props.filterField || props.column.field];
            data.push(element);
        });
    }
    return <div>
        <GridColumnMenuCheckboxFilter {...props} data={data} expanded={true} />
    </div>;
};

export const UniversalColumnMenu = (props) => {
    let temp = {
        ...props,
        filterOperators: customFilterOperators
    };
    return (
        <div>
            <GridColumnMenuSort {...props} />
            <GridColumnMenuFilter {...temp} />
            <GridColumnMenuGroup {...props} />
        </div>
    );
};

export const UniversalColumnCheckMenu = (props) => {
    let temp = {
        ...props,
        filterOperators: customFilterOperators
    };
    return (
        <div>
            <GridColumnMenuSort {...props} />
            <ColumnMenuCheckboxFilter {...temp} />
            <GridColumnMenuGroup {...props} />
        </div>
    );
};