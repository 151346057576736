import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isAuth } = useAuth()

    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuth()) {
                    return <Component {...props} />
                } else {
                    return (
                        <Redirect to={{
                            pathname: '/login',
                            state: { from: props.location }
                        }}
                        />
                    )
                }
            }}
        />
    )
}

export default PrivateRoute
