import React, { useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { DialogActionsBar } from '@progress/kendo-react-dialogs'
import functions, { checkPassPolicy } from "../../services/functions";
import { withRouter } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext';
import useLoading from '../../hooks/useLoading';
import { changePass } from '../../pages/services';
import { GetCookie, RemoveCookie } from '../../services/CookieService';
import { EXPIRY_PASS } from '../../constants/token.const';
import FibaInput from '../../components/input/FibaCustomInput';

const ChangePass = (props) => {
    const { addToast } = useToasts()
    const { auth } = useAuth()
    const { showLoading } = useLoading();
    const defaultValue = props.user;
    const { t } = useTranslation()

    const onSubmit = async (data) => {
        if (data.newpassword2 !== data.newpassword) {
            addToast(t('resetpass.notMatchPass'), { appearance: 'error' })
            return
        }

        if (data.oldpassword === data.newpassword) {
            addToast(t('resetpass.samePass'), { appearance: 'error' });
            return;
        }

        const chckPass = checkPassPolicy(data.newpassword, []);
        if (chckPass) {
            addToast(chckPass, { appearance: 'error' })
            return;
        }
        showLoading(true);
        const res = await changePass(
            functions.encrtypth(data.oldpassword),
            functions.encrtypth(data.newpassword),
            functions.encrtypth(data.newpassword2),
            auth?.user.userid);
        showLoading(false);
        if (res?.status === 'error') {
            addToast(res.message, { appearance: 'error' })
        } else {
            addToast(t('resetpass.successPassChange'), { appearance: 'success' });
            window.location.replace('/');
        }
    }

    useEffect(() => {
        if (GetCookie(EXPIRY_PASS)) {
            addToast("Нууц үгийн хүчинтэй хугацаа дууссан байна.", { appearance: 'error' });
            RemoveCookie(EXPIRY_PASS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="row justify-content-center mt-4">
                <div className="col-md-8">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <h3 className="page-title">{t('profile.title')}</h3>
                            </div>
                        </div>
                    </div>
                    <Form
                        onSubmit={onSubmit}
                        initialValues={defaultValue}
                        render={(formRenderProps) => (
                            <FormElement>
                                <div className="row justify-content-center">
                                    <div className="col-md-6" style={{ backgroundColor: "white" }}>
                                        <Field
                                            name={'oldpassword'}
                                            component={FibaInput}
                                            label={t('profile.old_password')}
                                            type="password"
                                            required={true}
                                        />
                                        <Field
                                            name={'newpassword'}
                                            component={FibaInput}
                                            label={t('profile.new_password')}
                                            type="password"
                                            required={true}
                                            validator={(value) => {
                                                return checkPassPolicy(value, []);
                                            }}
                                        />
                                        <Field
                                            name={'newpassword2'}
                                            component={FibaInput}
                                            label={t('profile.confirm_password')}
                                            type="password"
                                            required={true}
                                        />
                                        <DialogActionsBar layout={'end'}>
                                            <button type="button" className="k-button" onClick={formRenderProps.onFormReset}>
                                                {t('text.cancel')}
                                            </button>
                                            <button
                                                type="submit"
                                                className="k-button k-primary"
                                                disabled={!formRenderProps.allowSubmit}
                                            >
                                                {t('text.save')}
                                            </button>
                                        </DialogActionsBar>
                                    </div>
                                </div>
                            </FormElement>
                        )}
                    ></Form>
                </div>
            </div>
        </>
    )
}
export default withRouter(ChangePass);