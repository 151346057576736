import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bgLogin from "../../assets/img/back03.jpg";
import Arrow from "../../assets/img/arrow.png";
import logo from "../../assets/img/logos/logo_photo_white_500x86.png";
import { useLocation } from 'react-router-dom';
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { useToasts } from "react-toast-notifications";
import functions from "../../services/functions";
import { useAuth } from "../../contexts/AuthContext";
import { LangService } from "../../services/LangService";
import FibaInput from "../../components/input/FibaCustomInput";
import GoogleAuthLogo from '../../assets/img/logos/Google_Authenticator.png'
import useLoading from "../../hooks/useLoading";
import { requestForToken } from "../../firebase";

export default function Login() {
    const { t, i18n } = useTranslation();
    const { addToast } = useToasts()
    const { showLoading } = useLoading();
    const [values, setValues] = useState({
        email: "",
        password: "",
    });
    const { login, loginWithGoogle } = new useAuth();
    const [error, setError] = useState("");
    const [useGoogleAuth, setGoogle] = useState(false);
    const [passVisible, setPassVisible] = useState(false);
    const [googleAuth, setGoogleAuth] = useState({ auth_code: "", id: '', google_auth_key: '' });
    const inputRef = useRef(null);
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('i18nextLngDINg', lng);
    };
    const location = useLocation();
    const onSubmit = async () => {
        // console.log( await functions.hmacSha256Hex('bataa', 'ikhee'))
        setError('')
        const token = await requestForToken();
        if (values.email) {
            if (values.password && values.password.length > 1) {
                showLoading(true);
                const res = await login(values.email, functions.encrtypth(values.password), token);
                showLoading(false)
                checkedLogin(res);

            } else {
                setError(t('login.password_error'));
            }

        } else {
            setError(t('login.forgot_enter'));
        }
    };

    const checkedLogin = (res) => {
        if (res?.status === 'error') {
            setError(res.message);
        } else {
            if (location && location.state && location.state.from) {
                window.location.replace('/main');
            } else {
                if (res.use_google_auth) {
                    googleAuth.id = res.id;
                    googleAuth.google_auth_key = res.google_auth_key;
                    setGoogle(true);
                } else {
                    window.location.replace('/main');
                }
            }
        }
    }

    const checkGoogleAuth = async () => {
        if (googleAuth.auth_code) {
            showLoading(true);
            const res = await loginWithGoogle(values.email, functions.encrtypth(values.password), googleAuth.google_auth_key, googleAuth.auth_code);
            showLoading(false);
            checkedLogin(res);
        } else {
            addToast('Баталгаажуулах код оруулна уу.', { appearance: 'error' })
        }
    }

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13 || e.charCode === 13) {
            checkGoogleAuth();
        }
    };

    const changePass = (e) => {
        setValues((prev) => ({ ...prev, password: e.target.value }));
        setPassVisible(true);
    }

    useEffect(() => {
        if (passVisible) {
            inputRef.current.focus();
        }
    }, [passVisible]);

    useEffect(() => {
        const save = {
            backgroundColor: document.body.style.backgroundColor,
            // backgroundImage: document.body.style.backgroundImage,
            backgroundSize: document.body.style.backgroundSize,
            display: document.body.style.display,
            justifyContent: document.body.style.justifyContent,
            alignItems: document.body.style.alignItems,
            flexDirection: document.body.style.flexDirection,
            fontFamily: document.body.style.fontFamily,
            backdropFilter: document.body.style.backdropFilter,
        };

        document.body.style.backgroundColor = "rgba(255, 225, 225, 0.5)";
        document.body.style.backgroundSize = "cover";
        document.body.style.display = "flex";
        document.body.style.justifyContent = "center";
        document.body.style.alignItems = "center";
        document.body.style.flexDirection = "column";
        document.body.style.fontFamily = "roboto";
        // document.body.style.backdropFilter = "blur(15px) saturate(100%)";

        return () => {
            document.body.style.backgroundColor = save.backgroundColor;
            // document.body.style.backgroundImage = save.backgroundImage;
            document.body.style.backgroundSize = save.backgroundSize;
            document.body.style.display = save.display;
            document.body.style.justifyContent = save.justifyContent;
            document.body.style.alignItems = save.alignItems;
            document.body.style.flexDirection = save.flexDirection;
            document.body.style.fontFamily = save.fontFamily;
            // document.body.style.backdropFilter = save.backdropFilter;
        };
    }, []);

    return (
        <>
            <style>
                {`
                    body {
                        background-image: url('${bgLogin}');
                    }
                `}
            </style>
            <div style={{ position: "absolute", top: 30, right: 40 }}>
                {LangService &&
                    // eslint-disable-next-line array-callback-return
                    LangService.map((lang) => {
                        if (i18n.language !== lang.lng) {
                            return (
                                <a
                                    href="#/"
                                    key={lang.lng}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        changeLanguage(lang.lng);
                                    }}
                                >
                                    <img src={lang.icon} alt="Ding language" height={30} />
                                </a>
                            );
                        }
                    })}
            </div>
            <div className="container" style={{ height: "100vh" }}>
                <div
                    style={{
                        backgroundColor: "transparent",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        height: "100%",
                        textAlign: "center",
                        maxWidth: '340px'
                    }}
                >
                    <div className="row">
                        <div className="col-md-12 text-center mb-4">
                            <img src={logo} height={100} alt="Sain score" />
                        </div>
                    </div>
                    <p style={{ marginBottom: "20px", color: "#fff", fontSize: "24px", fontFamily: "roboto" }}>{t("login.title")}</p>
                    <div style={{ backgroundColor: "rgb(232, 240, 254)", border: "1px solid #32269c", padding: "5px 10px", margin: "5px 0", width: "100%", borderRadius: "10px" }}>
                        <table className="w-100">
                            <tbody>
                                <tr>
                                    <th style={{ textAlign: "left", background: "rgb(232, 240, 254)" }}>
                                        <input
                                            style={{ width: "100%", border: "none", background: "rgb(232, 240, 254)", filter: "unset" }}
                                            id="login_name"
                                            name="email"
                                            value={values.email}
                                            onChange={(e) => setValues((prev) => ({ ...prev, email: e.target.value }))}
                                            placeholder={t("login.login_name")}
                                            onKeyUp={(e) => {
                                                if (e.keyCode === 13) {
                                                    setPassVisible(true);
                                                }
                                            }}
                                        />
                                    </th>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div
                        id="hiddenDiv"
                        className={`w-100 show open`}
                        style={{ backgroundColor: "rgb(232, 240, 254)", border: "1px solid #32269c", padding: "5px 10px", margin: "5px 0", width: "100%", borderRadius: "10px" }}
                    >
                        <table className="w-100">
                            <tbody>
                                <tr>
                                    <th style={{ textAlign: "left", transition: "none", background: "rgb(232, 240, 254)" }}>
                                        <input
                                            style={{ maxlenght: "255", border: "none", background: "rgb(232, 240, 254)", transition: "none", filter: "unset" }}
                                            type="password"
                                            id="password"
                                            name="password"
                                            ref={inputRef}
                                            value={values.password}
                                            onChange={(e) => changePass(e)}
                                            placeholder={t("login.password")}
                                            onKeyUp={(e) => {
                                                if (e.keyCode === 13) onSubmit();
                                            }}
                                            className="w-100"
                                        />
                                    </th>
                                    <th style={{ background: "rgb(232, 240, 254)", textAlign: "right", transition: "none" }}>
                                        <button className="m-0 p-0" style={{ border: "none", background: "none" }} type="button" onClick={onSubmit}>
                                            <img src={Arrow} alt="" height={30} />
                                            {/* {isSubmitted ? <i className="spinner-border"></i> : t("login.login_button")} */}
                                        </button>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <Link to="/forgotpassword">{t("login.forgot_password")}</Link>
                    {error && (
                        <p style={{ marginTop: "30px", color: "#ff4c4c", fontSize: "16px" }}>
                            {/* {t("login.login_fail")} */}
                            {error}
                        </p>
                    )}
                    {/* </form> */}
                </div>
            </div>
            {
                useGoogleAuth && (
                    <Dialog width={400} height={250} className="pt-0 px-0">
                        <div className="row text-center">
                            <div className="col-12 px-0">
                                <div style={{ background: '#f1f1f1', width: '100%' }} className='pb-3'>
                                    <img src={GoogleAuthLogo} alt="GoogleAuthLogo" className='mt-3' />
                                    <h3 className="page-title">{t('top-right-menu.twoStepVer')}</h3>
                                </div>
                                <div className="mt-3">
                                    <FibaInput
                                        type="number"
                                        label="Google Authenticator Код"
                                        onKeyPress={handleKeypress}
                                        value={googleAuth.auth_code} onChange={(event) => {
                                            googleAuth.auth_code = event.target.value;
                                            setGoogleAuth({ ...googleAuth })
                                        }}
                                    />
                                </div>
                                <Button primary={true} look="flat"
                                    onClick={() => {
                                        setGoogle(false)
                                    }}>
                                    {t('text.cancel')}
                                </Button>
                                <Button primary={true} look="outline" onClick={checkGoogleAuth}>
                                    {t('text.submit')}
                                </Button>
                                {error && (
                                    <p style={{ marginTop: "5px", color: "#ff4c4c", fontSize: "14px" }}>
                                        {/* {t("login.login_fail")} */}
                                        {error}
                                    </p>
                                )}
                            </div>
                        </div>
                        <style>
                            {`
                                .k-dialog-content {
                                    padding-top: 0 !important;
                                }
                            `}
                        </style>
                    </Dialog>
                )
            }
        </>
    );
}
