import ENPNG from '../assets/img/flags/en.png'
import MNPNG from '../assets/img/flags/mn.png'

export const LangService = [
    { 
        name : 'MN',
        lng : 'mn',
        icon : MNPNG
    },
    { 
        name : 'EN',
        lng : 'en',
        icon : ENPNG
    },
]