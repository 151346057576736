import * as React from "react";
export const Breadcrumb = (props) => {
    return (
        <div className="page-header">
            <div className="row align-items-center">
                <div className="col">
                    <h3 className="page-title">{props.title}</h3>
                    {props.homeurl && <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href={props.homeurl}>{props.hometitle}</a>
                        </li>
                        <li className="breadcrumb-item active">{props.activetitle}</li>
                    </ul>}
                </div>
                {props.addbutton && <div className="col-auto float-right ml-auto">
                    <button
                        title={props.addbtntitle}
                        className="k-button k-primary"
                        onClick={() => {
                            props.addbutton()
                        }}
                    >
                        <i className="fiba-i-add" />{props.addbtntitle}
                    </button>
                    {props.paybutton && 
                    <button
                        title={props.paybtntitle}
                        className="k-button ml-3 k-primary"
                        onClick={() => {
                            props.paybutton()
                        }}
                    >
                        <i className={props.payIcon} />{props.paybtntitle}
                    </button>
                }

                </div>}

            </div>
        </div>
    );
};
export default Breadcrumb;