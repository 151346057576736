import React, { Suspense } from 'react'
import { ToastProvider, DefaultToastContainer } from 'react-toast-notifications'
import { FibaMsgContextProvider } from "./store/FibaMsgContextProvider";
import FibaMessageBox from "./components/model/FibaMessageBox";
import { LoadingContextProvider } from "./store/LoadingContextProvider";
import LoadingSpin from "./components/LoadingSpin";
// FIBA STYLE
import './assets/css/kendo-app.scss';
// import '@fiba/fiba-awesome/style.css'

//CSS Assets
import './assets/css/line-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from './contexts/AuthContext';
import Routes from './routes/Routes';

const Toast = (props) => <DefaultToastContainer {...props} style={{ marginTop: 60, zIndex: 999999 }} />

const loadingComp = <div className="k-loading-mask" style={{ zIndex: 100000 }}>
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
</div>

const App = () => {
    return (
        <>
            <Suspense fallback={loadingComp}>
                <LoadingContextProvider>
                    <FibaMsgContextProvider>
                        <ToastProvider
                            autoDismissTimeout="5000"
                            autoDismiss={true}
                            components={{ ToastContainer: Toast }}>
                            <AuthProvider>
                                <Routes />
                                <FibaMessageBox />
                                <LoadingSpin />
                            </AuthProvider>
                        </ToastProvider>
                    </FibaMsgContextProvider>
                </LoadingContextProvider>
            </Suspense>
        </>
    )
}
export default App;