import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ColumnMenu } from "../../components/grid/filter-components/columnMenu";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import ListScreen from "../../components/grid/grid";
import { process } from "@progress/kendo-data-query";
import functions from "../../components/grid/functions";
import NotifDetailScreen from "./NotifDetailScreen";
import { getNotifList } from "../services";
import { CommandCell } from "../../components/grid/column/accountCommandCell";
import { pagerSettings } from "../../components/grid/constant";
import useLoading from "../../hooks/useLoading";
import Breadcrumb from "../../components/model/Breadcrumb";

const createDataState = (dataState) => {
    return {
        result: process([], dataState),
        dataState: dataState,
    };
};

const NotifList = () => {
    const initPerPage = 20;
    const { t } = useTranslation();
    const { showLoading } = useLoading();
    const { addToast } = useToasts();
    const [notifState, setNotifState] = useState({ data: [] });
    let initialState = createDataState({ take: initPerPage, skip: 0 });
    const [dataStates, setDataStates] = useState(initialState.dataState);
    const [isDialog, setDialogModal] = useState(false);
    const [detailData, setDetailData] = useState({});

    const [callType, setCallType] = useState();
    const [requestData, setRequestData] = useState({
        orders: [],
        perPage: initPerPage,
        page: 1,
    });
    const fetch = async () => {
        showLoading(true);
        if (requestData.orders.length < 1) {
            requestData.orders = [{
                dir: 'desc',
                field: 'id'
            }]
        }
        const res = await getNotifList(requestData);
        if (res?.status === "error") {
            addToast(res.message, { appearance: "error" });
        } else {
            setNotifState(res);
        }
        showLoading(false);
    };

    const setAddModalState = async () => {
        setDetailData(null);
        setCallType("Send");
        setDialogModal(true);
    };

    const dataStateChange = async (event) => {
        const reqData = await functions.getListRequest(event.dataState);
        setRequestData(reqData);
        let updatedState = createDataState(event.dataState);
        setDataStates(updatedState.dataState);
    };


    const openDialogScreen = (dataItem) => {
        setDetailData(dataItem);
        setCallType("Detail");
        setDialogModal(true);
    };

    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestData]);

    const CommndCell = (props) => (
        <CommandCell {...props}
            optional={openDialogScreen}
            optionalIcon={"fiba-i-vmore"}
            optionalTitle={t("text.detail")}
            editField={"nonInEdit"}
            keyField={"id"} />
    );

    const grid = (
        <Grid
            data={notifState.data}
            style={{
                height: "100%",
            }}
            {...dataStates}
            total={notifState?.total}
            onDataStateChange={dataStateChange}
            pageable={pagerSettings}
            sortable={{ mode: "multiple" }}
            resizable={true}
        >
            <Column field={"created_at"} title={t("global.created_at")} filter="date" columnMenu={ColumnMenu} />
            <Column field={"inst_id"} title={t("page.inst")} filter="text" columnMenu={ColumnMenu} />
            <Column field={"title"} title={t("global.title")} columnMenu={ColumnMenu} />
            <Column width="60" cell={CommndCell} />
        </Grid>
    );
    return (
        <>
            <Breadcrumb
                title={t("page.notification")}
                addbutton={setAddModalState}
                addbtntitle={t("text.notifsend")}

            />
            <ListScreen grid={grid}></ListScreen>
            {isDialog && (
                <NotifDetailScreen
                    reload={fetch}
                    closeModal={() => {
                        setDialogModal(false);
                    }}
                    data={detailData}
                    modalState={isDialog}
                    callType={callType}
                />
            )}
        </>
    );
};
export default NotifList;
