import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";

const FibaInput = React.forwardRef((fieldRenderProps, ref) => {
    const { validationMessage, visited, required, label, fieldInfo, disabled, value, style, ...others } = fieldRenderProps;
    return (
        <label className="k-form-field text-left">
            <span>
                {label}
                {required && <span className="k-required">*</span>}
                {fieldInfo && <span className="k-field-info">{fieldInfo}</span>}
            </span>
            <Input required={required} disabled={disabled} {...others} value={value || ""} style={{...style,...{width: '100%'}}}/>
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </label>
    );
});
export default FibaInput;
