import React, { useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { DialogActionsBar } from '@progress/kendo-react-dialogs'
import GoogleAuthLogo from '../../assets/img/logos/Google_Authenticator.png'
import { Avatar } from '@progress/kendo-react-layout'
import { Button } from "@progress/kendo-react-buttons";
import { checkGoogleCode, updateUseGoogleAuth } from './service'
import useFibaMsgBox from '../../hooks/useFibaMsgBox'
import { useAuth } from '../../contexts/AuthContext';
import useLoading from '../../hooks/useLoading';
import FibaInput from '../../components/input/FibaCustomInput';

export default function GoogleAuthenticator(props) {
    const { addToast } = useToasts()
    const { auth } = useAuth()
    const { showLoading } = useLoading();
    const defaultValue = props.user;
    const [isCopy, setIsCopy] = useState(false);
    const googleQr = "";
    const [user, setUser] = useState({});
    const { t } = useTranslation()
    const { confirm } = useFibaMsgBox();

    const onSubmit = async (data) => {
        if (!data.auth_code) {
            addToast('Баталгаажуулах кодыг оруулна уу.', { appearance: 'error' })
            return
        }
        showLoading(true);
        const res = await checkGoogleCode(user.google_auth_key, data.auth_code);
        showLoading(false);
        if (res?.status === 'error') {
            addToast(res.message, { appearance: 'error' })
        } else {
            if (res.isSuccess) {
                const res1 = await updateUseGoogleAuth(1, auth.user.userid);
                if (res1?.status === 'error') {
                    addToast(res.message, { appearance: 'error' })
                } else {
                    user.use_google_auth = '1';
                    setUser(user);
                    addToast('Баталгаажуулалт амжилттай хийгдлээ.', { appearance: 'success' })
                }
            } else {
                addToast('Баталгаажуулах код буруу байна.', { appearance: 'error' })
            }
        }
    }

    const cancelGoogleAuth = async () => {
        const res = await confirm("Google Authenticator цуцлахдаа итгэлтэй байна уу?");
        if (res) {
            showLoading(true);
            const res1 = await updateUseGoogleAuth(0, auth.user.userid);
            showLoading(false);
            if (res1?.status === 'error') {
                addToast(res.message, { appearance: 'error' })
            } else {
                user.use_google_auth = '0';
                setUser(user);
                addToast('Амжилттай.', { appearance: 'success' })
            }
        }
    }

    const getUserInfo = async () => {
        // if (auth.user.userid) {
        //     showLoading(true);
        //     const res = await getUserData(auth.user.userid);
        //     showLoading(false);
        //     if (res?.status === 'error') {
        //         addToast(res.message, { appearance: 'error' })
        //     } else {
        //         setUser(res);
        //         getQrGenerate(res)
        //     }
        // } else {
        //     console.error('Системд нэвтэрсэн хэрэглэгчийн id олдсонгүй.')
        //     addToast('Уучлаарай системд алдаа гарлаа. Та түр хүлээгээд дахин оролдоно уу.', { appearance: 'error' })
        // }
    }


    useEffect(() => {
        getUserInfo();
        // getQrGenerate();
    }, [])

    return (
        <div className='pb-4'>
            <div className="row justify-content-center text-center" style={{ marginTop: '60px' }}>
                <div className="col-sm-8 col-md-4 col-lg-3 mt-3 px-0" style={{ background: 'white', boxShadow: "0 0 4px 0 rgba(0, 0, 0, .2)", borderRadius: '5px' }}>
                    <div style={{ background: '#f1f1f1' }} className='pb-3'>
                        <img src={GoogleAuthLogo} alt="GoogleAuthLogo" className='mt-3' />
                        <h3 className="page-title">{t('top-right-menu.twoStepVer')}</h3>
                    </div>
                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col-11"></div>
                    </div>
                    <p className='px-3 text-center pt-2'>
                        GA (Google Authenticator) нь динамик нууц үг үүсгэдэг бөгөөд энэ нь SMS
                        динамик тулгалттай адил юм. Google Authenticator програмыг холбосноос хойш
                        30 секунд тутамд шинэ код үүсгэдэг. Энэхүү баталгаажуулах кодыг нэвтрэх,
                        татан буулгах, аюулгүй байдлын тохиргоог өөрчлөх зэргээр аюулгүй байдлыг
                        дээд зэргээр хангах боломжтой.
                    </p>


                    <Avatar
                        shape="circle"
                        type='icon'
                    >
                        1
                    </Avatar>
                    <h3>
                        Google Authenticator татах
                    </h3>
                    <p className='px-3'>
                        iOS хэрэглэгчид App Store-д нэвтэрч ороод “Authenticator” гэж хайх боломжтой.
                        Андройд хэрэглэгчид Google Play руу нэвтрэх эсвэл гар утасны хөтөч дээрээ
                        “Google Authenticator” гэж хайх боломжтой.
                    </p>
                    <DialogActionsBar layout={'center'}>
                        <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer">
                            <Button iconClass="fiba-i-app_store" primary={true} look="outline">
                                App Store
                            </Button>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" rel="noopener noreferrer">
                            <Button iconClass='fiba-i-google_play' primary={true} look="outline">
                                Google Play
                            </Button>
                        </a>
                    </DialogActionsBar>

                    <Avatar
                        shape="circle"
                        type='icon'
                        className='mt-3'
                    >
                        2
                    </Avatar>
                    <h3>
                        Google Authenticator тохируулах
                    </h3>
                    <p className='px-3 mb-2'>
                        GA-г нээгээд доорхи QR кодыг сканнердах эсвэл токен нэмэх түлхүүрийг оруулна уу.
                    </p>

                    <img src={googleQr} alt="GoogleAuthLogo" className='mt-3' />
                    <p className='mt-2'>
                        {t('text.key')}: <br />{user.google_auth_key}
                        <Button
                            iconClass='la la-copy'
                            title={isCopy ? `Copied: ${user.google_auth_key}` : 'Copy to clipboard'}
                            primary={true}
                            look="flat"
                            onClick={() => {
                                navigator.clipboard.writeText(user.google_auth_key)
                                setIsCopy(true);
                            }}
                        >Copy</Button>
                    </p>
                    <div className="alert alert-warning mx-3 mt-3" role="alert">
                        Энэ түлхүүр нь утсаа солих эсвэл алдах үед таны GA-г авахад ашиглагддаг.
                        GA-г холбохоосоо өмнө түлхүүрээ хадгалаад нөөцлөөрэй.
                    </div>

                    <Avatar
                        shape="circle"
                        type='icon'
                    >
                        3
                    </Avatar>
                    {
                        user.use_google_auth === '1' && (
                            <>
                                <div className="alert alert-success mx-3 mt-3" role="alert">
                                    Google Authenticator баталгаажсан
                                </div>
                                <button
                                    type="submit"
                                    className="k-button k-primary mb-4"
                                    onClick={cancelGoogleAuth}
                                >
                                    Google Authenticator {t('text.cancel')}
                                </button>
                            </>
                        )
                    }
                    {
                        user.use_google_auth !== '1' && (
                            <>
                                <p className='pt-1 mb-1'>
                                    GA-s үүсгэсэн баталгаажуулах кодыг оруулна уу.
                                    {false && 'Enter the verification code generated by your mobile application.'}
                                </p>
                                <Form
                                    onSubmit={onSubmit}
                                    initialValues={defaultValue}
                                    render={(formRenderProps) => (
                                        <FormElement>
                                            <div className="row justify-content-center pb-5 pl-4 pr-0">
                                                <div className="col-md-6">
                                                    <Field
                                                        name={'auth_code'}
                                                        placeholder="123456"
                                                        label="GA Код"
                                                        component={FibaInput}
                                                        required={true}
                                                        type="number"
                                                    />
                                                </div>
                                                <div className="col-md-5" style={{ paddingTop: '25px' }}>
                                                    <DialogActionsBar layout={'end'}>
                                                        <button
                                                            type="submit"
                                                            className="k-button k-primary"
                                                            disabled={!formRenderProps.allowSubmit}
                                                        >
                                                            {t('text.submit')}
                                                        </button>
                                                    </DialogActionsBar>
                                                </div>
                                            </div>
                                        </FormElement>
                                    )}
                                ></Form>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
