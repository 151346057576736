import * as React from "react";
import useConfirm from "../../hooks/useFibaMsgBox";
import { useTranslation } from 'react-i18next'
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { CONFIRM_BOX } from "../../store/MsgReducer";


export const FibaMessageBox = () => {
    const { onConfirm, onCancel, msgBoxState } = useConfirm();
    const { t } = useTranslation()
    const component = msgBoxState.show ? (
        <Dialog onClose={onCancel} width={400}>
            <div className={`fiba-messagebox fiba-messagebox-${msgBoxState.type}`}>
                <div className="fiba-messagebox-title">{t(msgBoxState.title)}</div>
                <span className="fiba-messagebox-icon">
                    <i aria-hidden="true"
                        className={msgBoxState.icon}></i>
                </span>
                <div className="fiba-messagebox-body">
                    {msgBoxState.text}
                </div>
                {
                    (msgBoxState.type === CONFIRM_BOX) ?
                        <DialogActionsBar>
                            <button className="k-button btn-primary" onClick={onConfirm}>
                                {t("text.yes")}
                            </button>
                            <button className="k-button" onClick={onCancel}>
                                {t("text.no")}
                            </button>
                        </DialogActionsBar> :
                        <DialogActionsBar layout="center">
                            <button className="k-button btn-primary" onClick={onConfirm}>
                                {t("text.close")}
                            </button>
                        </DialogActionsBar>
                }
            </div>
        </Dialog>
    ) : null;
    return component;
};
export default FibaMessageBox;
