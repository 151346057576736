import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/img/logos/logo_photo_white_500x86.png";
import { useParams } from "react-router-dom";
import { API_AUTH_USER_RESETPASS } from "../../constants/url.constants";
import bgLogin from "../../assets/img/back03.jpg";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { useToasts } from "react-toast-notifications";
import { useHistory } from 'react-router-dom';

import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import functions, { checkPassPolicy } from "../../services/functions";
import useLoading from "../../hooks/useLoading";
import ApiService from "../../services/ApiService";
import { LangService } from "../../services/LangService";
import FibaInput from "../../components/input/FibaCustomInput";

export default function ResetPassword() {
    const { token } = useParams();
    const { t, i18n } = useTranslation();
    const { showLoading } = useLoading();
    const history = useHistory();
    const { addToast } = useToasts();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const handleSubmit = (dataItem) => {
        if (dataItem.password === dataItem.password2) {
            const chckPass = checkPassPolicy(dataItem.password, []);
            if (chckPass) {
                addToast(chckPass, { appearance: 'error' })
                return;
            }
            showLoading(true);
            ApiService("post", API_AUTH_USER_RESETPASS, {
                token: token,
                password: functions.encrtypth(dataItem.password),
                password_confirmation: functions.encrtypth(dataItem.password2),
            })
                .then((res) => {
                    addToast("Нууц үг амжилттай солигдлоо.", { appearance: "success" });
                    history.replace('/login');
                })
                .catch((err) => {
                    addToast(functions.getInfoRespError(err), { appearance: "error" });
                })
                .finally(() => {
                    showLoading(false);
                });
        } else {
            addToast("Нууц үг таарахгүй байна.", { appearance: "error" });
        }
    }

    useEffect(() => {
        const save = {
            backgroundColor: document.body.style.backgroundColor,
            backgroundSize: document.body.style.backgroundSize,
            display: document.body.style.display,
            justifyContent: document.body.style.justifyContent,
            alignItems: document.body.style.alignItems,
            flexDirection: document.body.style.flexDirection,
            fontFamily: document.body.style.fontFamily,
            backdropFilter: document.body.style.backdropFilter,
        };
        document.body.style.backgroundSize = "cover";
        document.body.style.display = "flex";
        document.body.style.justifyContent = "center";
        document.body.style.alignItems = "center";
        document.body.style.flexDirection = "column";
        document.body.style.fontFamily = "Montserrat";
        // document.body.style.backdropFilter= 'blur(25px) saturate(100%)';

        return () => {
            document.body.style.backgroundSize = save.backgroundSize;
            document.body.style.display = save.display;
            document.body.style.justifyContent = save.justifyContent;
            document.body.style.alignItems = save.alignItems;
            document.body.style.flexDirection = save.flexDirection;
            document.body.style.fontFamily = save.fontFamily;
            // document.body.style.backdropFilter= save.backdropFilter
        };
    }, []);

    return (
        <>
            <style>
                {`
                    body {
                        background-image: url('${bgLogin}');
                    }
                `}
            </style>
            <div style={{ position: "absolute", top: 30, right: 40 }}>
                {LangService &&
                    // eslint-disable-next-line array-callback-return
                    LangService.map((lang) => {
                        if (i18n.language !== lang.lng) {
                            return (
                                <a
                                    href="#/"
                                    key={lang.lng}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        changeLanguage(lang.lng);
                                    }}
                                >
                                    <img src={lang.icon} alt="" height={20} />
                                </a>
                            );
                        }
                    })}
            </div>
            <div className="main-wrapper">
                <Helmet>
                    <title>Reset Password - SAINSCORE - Зээлийн мэдээллийн сан</title>
                </Helmet>
                {/* <a href="/orange/applyjob/joblist" className="btn btn-primary apply-btn">Apply Job</a> */}
                <div className="container" style={{ height: "100vh" }}>
                    <div
                        style={{
                            backgroundColor: "transparent",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            height: "100%",
                            textAlign: "center",
                            maxWidth: '340px'
                        }}>
                        <div className="row">
                            <div className="col-md-12 text-center mb-4">
                                <img src={logo} height={70} href="/" alt="Sainscore" />
                            </div>
                        </div>
                        <div className="card px-3 py-4" style={{ borderRadius: "10px", borderColor: "transparent" }}>
                            <div className="account-wrapper">
                                <h3 className="account-title">
                                    {t("login.forgot_title")}
                                </h3>
                                <Form
                                    onSubmit={handleSubmit}
                                    render={(formRenderProps) => (
                                        <FormElement>
                                            <fieldset className={"k-form-fieldset"}>
                                                <Field
                                                    id={"password"}
                                                    name={"password"}
                                                    label={t("resetpass.new_password")}
                                                    component={FibaInput}
                                                    type="password"
                                                    required={true}
                                                    validator={(value) => {
                                                        return checkPassPolicy(value, []);
                                                    }}
                                                />
                                                <Field
                                                    id={"password2"}
                                                    name={"password2"}
                                                    label={t("resetpass.confirm_password")}
                                                    component={Input}
                                                    type="password"
                                                    required={true}
                                                />
                                                <div className="k-form-buttons mt-4 col text-center">
                                                    <DialogActionsBar>
                                                        <Button
                                                            primary={true}
                                                            type={"submit"}
                                                            disabled={!formRenderProps.allowSubmit}
                                                        >
                                                            {t("login.confirm")}
                                                        </Button>
                                                        <Link to="/login">
                                                            <Button
                                                                primary={true}
                                                                type={"submit"}
                                                                look="outline"
                                                                to="/login"
                                                            >
                                                                {t("login.login_button")}
                                                            </Button>
                                                        </Link>
                                                    </DialogActionsBar>
                                                </div>
                                            </fieldset>
                                        </FormElement>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}