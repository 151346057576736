import * as React from "react";
import { Error, Hint } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
export const FibaTextArea = (fieldRenderProps) => {
    const { validationMessage, touched, label, id, hint, disabled, optional, required, fieldInfo, value, valid, json, visited, modified, onChange, ...others } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const validT = valid + "" ?? "";
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    let valueArea = value ?? "";
    const changeInputValue = () => {
        if (json) {
            try {
                valueArea = JSON.stringify(JSON.parse(valueArea), null, 4);
            } catch (e) {
                console.error(label + " талбар JSON format-тай байх ёстой.");
            }
            onChange({ value: valueArea });
        }
    };

    return (
        <label className="k-form-field">
            <span>
                {label}
                {required && <span className="k-required">*</span>}
                {json && (
                    <span>
                        <Button
                            iconClass="fiba-i-json"
                            title={"JSON format"}
                            type="button"
                            primary={true}
                            look="flat"
                            onClick={() => {
                                changeInputValue();
                            }}
                        ></Button>
                    </span>
                )}
            </span>
            <textarea
                className="k-textbox"
                value={valueArea.toString()}
                valid={validT}
                id={id}
                visited={visited ? visited + "" : ""}
                modified={modified ? modified + "" : ""}
                disabled={disabled}
                onChange={onChange}
                style={{ overflowY: "scroll", height: "100%", padding: "4px 8px" }}
                aria-describedby={`${hintId} ${errorId}`}
                {...others}
            ></textarea>
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {fieldInfo && <span className="k-field-info">*{fieldInfo}</span>}
            {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
        </label>
    );
};
