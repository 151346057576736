import { Operator } from "./operators"
import { Fields } from "./fields"
export const customFilterOperators = {
    "text": [
        {
            "text": Fields.filterEqOperator,
            "operator": Operator.eq
        },
        // {
        //     "text": Fields.filterNotEqOperator,
        //     "operator": Operator.neq
        // },
        // {
        //     "text": Fields.filterStartsWithOperator,
        //     "operator": Operator.startswith
        // },
        // {
        //     "text": Fields.filterEndsWithOperator,
        //     "operator": Operator.endswith
        // },
        // {
        //     "text": Fields.filterIsNullOperator,
        //     "operator": Operator.isnull
        // },
        // {
        //     "text": Fields.filterIsNotNullOperator,
        //     "operator": Operator.isnotnull
        // },
        {
            "text": Fields.filterContainsOperator,
            "operator": Operator.contains
        }
    ],
    "numeric": [
        {
            "text": Fields.filterEqOperator,
            "operator": Operator.eq
        },
        // {
        //     "text": Fields.filterNotEqOperator,
        //     "operator": Operator.neq
        // },
        {
            "text": Fields.filterGteOperator,
            "operator": Operator.gte
        },
        // {
        //     "text": Fields.filterGtOperator,
        //     "operator": Operator.gt
        // },
        {
            "text": Fields.filterLteOperator,
            "operator": Operator.lte
        },
        // {
        //     "text": Fields.filterLtOperator,
        //     "operator": Operator.lt
        // },
        // {
        //     "text": Fields.filterIsNullOperator,
        //     "operator": Operator.isnull
        // },
        // {
        //     "text": Fields.filterIsNotNullOperator,
        //     "operator": Operator.isnotnull
        // }
    ],
    "date": [
        {
            "text": Fields.filterEqOperator,
            "operator": Operator.eq
        },
        // {
        //     "text": Fields.filterNotEqOperator,
        //     "operator": Operator.neq
        // },
        {
            "text": Fields.filterAfterOrEqualOperator,
            "operator": Operator.gte
        },
        // {
        //     "text": Fields.filterAfterOperator,
        //     "operator": Operator.gt
        // },
        // {
        //     "text": Fields.filterBeforeOperator,
        //     "operator": Operator.lt
        // },
        {
            "text": Fields.filterBeforeOrEqualOperator,
            "operator": Operator.lte
        },
        // {
        //     "text": Fields.filterIsNullOperator,
        //     "operator": Operator.isnull
        // },
        // {
        //     "text": Fields.filterIsNotNullOperator,
        //     "operator": Operator.isnotnull
        // }
    ],
    "boolean": [
        {
            "text": Fields.filterEqOperator,
            "operator": Operator.eq
        }
    ]
}