import React, { useState, useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { deleteProduct, deleteProductBranch, getProduct, setCountProductsBranch } from "../services";
import ProductDetail from "./ProductDetail";
import { pagerSettings } from "../../components/grid/constant";
import Breadcrumb from "../../components/model/Breadcrumb";
import useLoading from "../../hooks/useLoading";
import useFibaMsgBox from "../../hooks/useFibaMsgBox";
import functions from "../../components/grid/functions";
import { MyCommandCell } from "../../components/grid/myCommandCell";
import ListScreen from "../../components/grid/grid";
// import ProductAddBranch from "./ProductAddBranch";

const createDataState = (dataState) => {
    return {
        result: process([], dataState),
        dataState: dataState,
    };
};

export default function ProductList(props) {
    const { addToast } = useToasts();
    const { showLoading } = useLoading();
    const [insts, setInsts] = useState();
    const initPerPage = 20;
    const { confirm } = useFibaMsgBox();
    const [modal, setModal] = React.useState({
        state: false,
        data: null
    });
    const { t } = useTranslation();
    const [requestData, setRequestData] = useState({
        orders: [],
        perPage: initPerPage,
        page: 1,
        filters: [],
        withClient: 1,
    });
    let initialState = createDataState({ take: initPerPage, skip: 0 });
    const [dataState, setDataState] = React.useState(initialState.dataState);
    const [tmpData, setTmpData] = React.useState([]);
    const setAddModal = () => {
        setModal({ state: true, data: null });
    };

    const saveChangesCount = async () => {
        const chngsData = [];
        for (let index = 0; index < tmpData.length; index++) {
            const element = tmpData[index];
            for (let ind = 0; ind < insts.length; ind++) {
                const elm = insts[ind];
                if (element.id === elm.id && element.count === elm.count) {
                    chngsData.push({
                        branch_id: props?.branchid,
                        product_id: element.id,
                        count: element.count,
                    })
                }
            }
        }
        if (chngsData.length > 0) {
            showLoading(true);
            const res = await setCountProductsBranch(chngsData);
            showLoading(false);
            if (res?.status === "error") {
                addToast(res.message, { appearance: "error" });
            } else {
                addToast(t("text.successsave"), { appearance: "success" });
                fetch();
            }
        }
    }

    const fetch = async () => {
        showLoading(true);
        //requestData.filters.push({ cond: "=", value: true, field: "isadmin" });
        if (props?.instid) {
            requestData.inst_id = props?.instid;
        }
        if (props?.branchid) {
            requestData.branch_id = props?.branchid;
        }
        const res = await getProduct(requestData);
        if (res?.status === "error") {
            addToast(res.message, { appearance: "error" });
        } else {
            if (res) {
                let data = res;
                if (res.data) {
                    data = res.data;
                }
                const tmp = [];
                const tmp1 = [];
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    element.inEdit = true;
                    tmp.push(element);
                    tmp1.push(element);
                }
                setTmpData(tmp);
                setInsts(tmp1);
            }
        }
        showLoading(false);
    };

    const deleteUser = async (id) => {
        showLoading(true);
        let res;
        if (props?.branchid) {
            res = await deleteProductBranch({
                branch_id: props?.branchid,
                product_id: id
            });
        } else {
            res = await deleteProduct(id);
        }
        if (res?.status === "error") {
            addToast(res.message, { appearance: "error" });
        } else {
            fetch();
            addToast(t("text.successdelete"), { appearance: "success" });
        }
        showLoading(false);
    };

    const openUpdateModal = (dataItem) => {
        setModal({ state: true, data: dataItem });
    };

    const dataStateChange = async (event) => {
        const reqData = await functions.getListRequest(event.dataState);
        setRequestData(reqData);
        let updatedState = createDataState(event.dataState);
        setDataState(updatedState.dataState);
    };

    useEffect(() => {
        fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestData]);

    const remove = async (dataItem) => {
        const isConfirmed = await confirm("Устгахдаа итгэлтэй байна уу?");
        if (isConfirmed) {
            deleteUser(dataItem.id);
        } else {
            console.log("Declined.");
        }
    };

    const choosedData = (event) => {
        if (props.chooseData) {
            props.chooseData(event.dataItem);
        }
    };

    const CommandCell = (props) => <MyCommandCell {...props} edit={props?.branchid ? null : openUpdateModal} remove={remove} editField={"nonInEdit"} keyField={"id"} />;
    const deleteitemProd = props => {
        const {
            dataItem
        } = props;

        return <td className="k-command-cell">
            <button
                className="k-button"
                title={t('text.delete')}
                onClick={() => remove(dataItem)}
            >
                <i className="fiba-i-delete" />
            </button>
        </td>;
    };

    const itemChange = (e) => {
        let newData = insts.map((item) => {
            if (item.id === e.dataItem.id) {
                item[e.field || ""] = e.value;
            }

            return item;
        });
        setInsts(newData);
    };

    const grid = (
        <Grid
            style={{ height: "77vh" }}
            {...dataState}
            data={insts}
            total={insts?.total}
            onDataStateChange={dataStateChange}
            pageable={pagerSettings}
            sortable={{ mode: "multiple" }}
            onRowDoubleClick={choosedData}
            editField="inEdit" onItemChange={itemChange}
        >
            <GridColumn field="id" title={t("page.id")} editable={false} />
            <GridColumn field="name" title={t("page.name")} editable={false} />
            <GridColumn field="price" title={t("page.price")} editable={false} />
            {props?.branchid && <GridColumn field="count" title={t("page.count")} editor="numeric" />}
            <GridColumn field="created_at" title={t("page.created_at")} format="{0:dd/MM/yyyy}" editable={false} />
            {!props.chooseData && <GridColumn width="100" cell={props.branchid ? deleteitemProd : CommandCell} />}
        </Grid>
    );

    return (
        <>
            {!props.chooseData && <Breadcrumb
                title={t("page.product")}
                addbutton={props?.branchid ? saveChangesCount : setAddModal}
                addbtntitle={props?.branchid ? `${t("text.save")}` : `${t("page.product")} ${t("text.add")}`}
            />}

            <ListScreen grid={grid}></ListScreen>
            {(modal.state && !props?.branchid) && (
                <ProductDetail
                    reload={fetch}
                    closeModal={() => {
                        setModal({ state: false });
                        fetch();
                    }}
                    modalState={modal}
                />
            )}
            {/* {(modal.state && props?.branchid) && (
                <ProductAddBranch
                    reload={fetch}
                    closeModal={() => {
                        setModal({state: false});
                        fetch();
                    }}
                    modalState={modal}
                />
            )} */}
        </>
    );
}
