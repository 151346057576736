import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../../../assets/img/404.png";
export default function error404() {
    return (
        <div style={{width: '100wh'}}>
            <main>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <img src={logo} alt="SainScore" width={'100%'}/>
                        </div>
                        <div className="col-md-6 align-self-center">
                            <h1>404</h1>
                            <h2>UH OH! You're lost.</h2>
                            <p>The page you are looking for does not exist.
                                How you got here is a mystery. But you can click the button below
                                to go back to the homepage.
                            </p>
                            <Link to="/">
                                <button className="k-button k-primary">HOME</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </main>
            <style>
                {`
                    @import url("https://fonts.googleapis.com/css?family=Nunito+Sans");
                    :root {
                        --blue: #0e0620;
                        --white: #fff;
                        --green: #2ccf6d;
                    }
                    html,
                    body {
                        height: 100%;
                    }
                    body {
                        font-family: "Nunito Sans";
                        color: var(--blue);
                        font-size: 1em;
                    }
                    button {
                        font-family: "Nunito Sans";
                    }
                    ul {
                        list-style-type: none;
                        padding-inline-start: 35px;
                    }
                    svg {
                        width: 100%;
                        visibility: hidden;
                    }

                    nav {
                        position: absolute;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: var(--green);
                        color: var(--blue);
                        width: 300px;
                        z-index: 1;
                        padding-top: 80px;
                        transform: translateX(-100%);
                        transition: 0.24s cubic-bezier(0.52, 0.01, 0.8, 1);
                        & li {
                            transform: translateX(-5px);
                            transition: 0.16s cubic-bezier(0.44, 0.09, 0.46, 0.84);
                            opacity: 0;
                        }
                        & a {
                            display: block;
                            font-size: 1.75em;
                            font-weight: bold;
                            text-decoration: none;
                            color: inherit;
                            transition: 0.24s ease-in-out;
                            &:hover {
                                text-decoration: none;
                                color: var(--white);
                            }
                        }
                        &[data-state="open"] {
                            transform: translateX(0%);
                            & ul {
                                @for $i from 1 through 4 {
                                    li:nth-child(#{$i}) {
                                        transition-delay: 0.16s * $i;
                                        transform: translateX(0px);
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                    .btn {
                        z-index: 1;
                        overflow: hidden;
                        background: transparent;
                        position: relative;
                        padding: 8px 50px;
                        border-radius: 30px;
                        cursor: pointer;
                        font-size: 1em;
                        letter-spacing: 2px;
                        transition: 0.2s ease;
                        font-weight: bold;
                        margin: 5px 0px;
                        &.green {
                            border: 4px solid var(--green);
                            color: var(--blue);
                            &:before {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 0%;
                                height: 100%;
                                background: var(--green);
                                z-index: -1;
                                transition: 0.2s ease;
                            }
                            &:hover {
                                color: var(--white);
                                background: var(--green);
                                transition: 0.2s ease;
                                &:before {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    @media screen and (max-width: 768px) {
                        body {
                            display: block;
                        }
                        .container {
                            margin-top: 70px;
                            margin-bottom: 70px;
                        }
                    }

                `}
            </style>
        </div>
    )
}
